import React, {
  useContext,
  useEffect, useState,
} from 'react'
import {
  Box, Container, PropTypes, useMediaQuery,
} from '@material-ui/core'
import {
  HobsProduct,
} from '../../api/Packages'
import Package, {
  ButtonPropsExtended, PackageProps,
} from './Package'
import { TrustPilotBoxProps } from '../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import PricingPackageTabs, { Tab } from './PricingPackageTabs'
import { TypographyProps } from '../Typography/Typography'
import SliderCarousel from './SliderCarousel'
import {
  getStandardPrice, getDiscountPrice,
  getSpeed, getProductTerm, getBBSpeed,
} from '../../utils/getProductDetails'
import Bundles, { BundlesProps } from '../Bundles/Bundles'
import theme from '../../styles/theme'
import {
  ChannelType, SegmentType,
} from '../../utils/commonEnums'
import PackagesFooter from './PackagesFooter'
import OfferBanner, { OfferBannerProps } from '../OfferBanner/OfferBanner'
import Wrapper from '../Wrapper/Wrapper'
import LoadingSpinner from '../SearchBar/LoadingSpinner'
import classes from './Packages.styles'
import WarningMessage, { WarningMessageColor } from '../WarningMessage/WarningMessage'
import { ProductsContext } from '../ProductsContext/ProductsContext'
import { getHybridPackages } from '../../utils'
import Heading, { HeadingProps } from '../Heading/Heading'
import ValueProps, { ValuePropsProps } from '../ValueProps/ValueProps'
import marketingEvents from '../../utils/marketing/marketingEvents'

export interface PackagesProps {
  name?: string;
  heading?: HeadingProps;
  offer_banner?: OfferBannerProps;
  offer_banner_position?: string;
  value_props?: ValuePropsProps;
  packages: PackageProps[];
  bundles?: BundlesProps;
  footnote?: TypographyProps;
  footnote_link?: LinkProps;
  trustpilot?: TrustPilotBoxProps;
  anchor?: string;
  file_size?: number;
  contract_terms?: ContractLengthChoices[];
  package_align?: PropTypes.Alignment;
  package_cta: ButtonPropsExtended;
  package_cta_secondary?: ButtonPropsExtended;
  free_set_up_copy?: string;
  package_card_flip: boolean;
  can_be_selected: boolean;
  on_click_package?: (product: HobsProduct, cartInstanceIdentifier: string, channel: string, hide_strikethrough: boolean) => void;
  on_click_bundle?: (product: HobsProduct, cartInstanceIdentifier: string, channel: string) => void;
  stacked_cards_on_mobile?: boolean;
  customer_segment?: SegmentType;
  show_free_months?: boolean;
  background_gradient?: boolean;
  hide_strikethrough?: boolean;
  channel?: ChannelType;
  default_contract_length?: string;
}

export interface ContractLengthChoices {
  readonly title: string;
  readonly title_text?: string;
  readonly body_text?: string;
  readonly type: 'ANNUAL_24' | 'ANNUAL' | 'MONTHLY';
  title_2: TypographyProps;
  subtitle?: TypographyProps;
  contract_length?: number;
}

export interface LinkProps {
  label?: string;
  href?: string;
}
/* eslint complexity: ["error", 100] */
function Packages({
  customer_segment,
  packages,
  offer_banner,
  offer_banner_position,
  value_props,
  bundles,
  footnote,
  footnote_link,
  trustpilot,
  anchor,
  file_size,
  contract_terms,
  on_click_package,
  on_click_bundle,
  package_cta,
  can_be_selected,
  package_cta_secondary,
  free_set_up_copy,
  package_card_flip,
  package_align,
  stacked_cards_on_mobile,
  show_free_months,
  background_gradient,
  default_contract_length,
  hide_strikethrough,
  heading,
}: PackagesProps) {
  const [
    activeTabIndex,
    setActiveTabIndex,
  ] = useState<number>()

  const [
    startSpeedAnimation,
    setStartSpeedAnimation,
  ] = useState<boolean>(true)

  const [
    activeTermLength,
    setActiveTermLength,
  ] = useState<number>(24)

  const [
    errorMessage,
    setErrorMessage,
  ] = useState(false)

  const [
    disabledButton,
    setDisabledButton,
  ] = useState(false)

  const {
    options, setOptions,
  } = useOrderContext()

  const [
    noTerms,
    setNoTerms,
  ] = useState<boolean>(false)

  const contractTerms = contract_terms || []

  const tabs = contractTerms.map(
    ({
      type,
      title,
      title_2,
      subtitle,
      contract_length,
    }: ContractLengthChoices): Tab => ({
      type,
      label: title,
      title_2,
      subtitle,
      contract_length,
    }),
  )

  const {
    broadband,
    bundle,
    packagesTerm,
  } = options

  const setTermInOptions = (term: number) => {
    setOptions(
      {
        ...options,
        packagesTerm: {
          ...packagesTerm,
          [customer_segment!]: term,
        },
      },
    )
  }

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newActiveIndex: number,
  ) => {
    const term = tabs[newActiveIndex]?.contract_length
    setStartSpeedAnimation(false)

    if (term) {
      setActiveTab(term)
      setTermInOptions(term)
    }
  }

  const setActiveTab = (term: number) => {
    setActiveTermLength(term)
    setActiveTabIndex(tabs?.findIndex(e => e.contract_length === term))
  }

  const setTermAndOptions = (term: number) => {
    setTermInOptions(term)
    setActiveTab(term)
  }

  const defaultCMSTerm = Number(default_contract_length)

  useEffect(() => {
    const getFirstTabTerm = tabs[0]?.contract_length
    const defaultStateIsAvailable = tabs.some(e => e.contract_length === activeTermLength)
    const storedTerm = packagesTerm[customer_segment!]
    const storedTermExists = tabs.some(e => e.contract_length === storedTerm)

    if (tabs.length === 0) {
      return setNoTerms(true)
    }

    if (storedTerm && storedTermExists) {
      return setActiveTab(storedTerm)
    }

    if (defaultCMSTerm) {
      return setTermAndOptions(defaultCMSTerm)
    }

    if (defaultStateIsAvailable) {
      return setTermAndOptions(activeTermLength)
    }

    if (getFirstTabTerm) {
      return setTermAndOptions(getFirstTabTerm)
    }
  }, [
    packagesTerm,
    customer_segment,
  ])

  const { dataPackages } = useContext(ProductsContext)
  const hybridPackages = getHybridPackages(dataPackages!, packages!)

  useEffect(() => {
    setTimeout(() => {
      if (hybridPackages === undefined || hybridPackages?.length === 0) {
        setErrorMessage(true)
      } else {
        setErrorMessage(false)
      }
    }
    , 30000)
  }, [hybridPackages])

  useEffect(() => {
    errorMessage && marketingEvents.error(options, 'ERR-PACKAGES-1', 'Packages timeout', 'Packages timed out.')
  }, [errorMessage])

  const callback = (val: boolean) => {
    setStartSpeedAnimation(val)
  }

  const setDisableButton = () => {
    setDisabledButton(true)
    setTimeout(() => {
      setDisabledButton(false)
    }
    , 3000)
  }

  const createPackageProps = (item: PackageProps) => ({
    customer_segment,
    display_name: item.display_name,
    button: item.button && item.button.text ? item.button : package_cta,
    button_secondary: item.button_secondary && item.button_secondary.text ? item.button_secondary : package_cta_secondary,
    price: item.price ? item.price : getStandardPrice(item),
    flip: package_card_flip,
    anchor: item.anchor,
    flag: item.flag,
    bullets_with_icons: item.bullets_with_icons,
    voucher: item.voucher,
    price_guidance: item?.price_guidance,
    name_first_line: item?.name_first_line,
    name_second_line: item?.name_second_line,
    strikethrough_name: item?.strikethrough_name,
  })

  const packagesFilteredCards = customer_segment === SegmentType.BUSINESS ?
    packages?.map((item: PackageProps, index: number) => {
      const itemTerm = Number(item.contract_length)
      return (
        (activeTermLength === itemTerm || noTerms) &&
          <Package
            key={index}
            bullet_align="center"
            simple_bullets={item.simple_bullets}
            bullets_back={item.bullets_back}
            upload_speed={getBBSpeed(item)}
            hide={item.hide}
            file_size={file_size}
            startSpeedAnimation={startSpeedAnimation}
            align={item.align ? item.align : package_align}
            setDisableButton={setDisableButton}
            disabledButton={disabledButton}
            {...createPackageProps(item)}
          />
      )
    }) :
    hybridPackages?.map((item: PackageProps, index: number) => {
      return (
        (activeTermLength === getProductTerm(item) || noTerms) &&
          <Package
            key={index}
            product={item}
            simple_bullets={item.simple_bullets}
            bullets_back={item.bullets_back}
            upload_speed={getSpeed(item, 'UPLOAD_SPEED')}
            download_speed={getSpeed(item, 'DOWNLOAD_SPEED')}
            file_size={file_size}
            startSpeedAnimation={startSpeedAnimation}
            discount={getDiscountPrice(item)}
            hide={item?.hide}
            on_click_package={on_click_package}
            selected={can_be_selected && (item?.productOfferingId === broadband?.productOfferingId || item?.productOfferingId === bundle?.productOfferingId)}
            term={getProductTerm(item)}
            align={item.align ? item.align : package_align}
            show_free_months={show_free_months}
            hide_strikethrough={hide_strikethrough}
            out_of_contract_copy={item?.out_of_contract_copy}
            setDisableButton={setDisableButton}
            disabledButton={disabledButton}
            free_set_up_copy={free_set_up_copy}
            {...createPackageProps(item)}
          />
      )
    })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const offerBanner = offer_banner ? Object.entries(offer_banner).length > 0 : false
  const valueProps = value_props ? Object.entries(value_props).length > 0 : false
  const packagesProps =
    isMobile && stacked_cards_on_mobile ?
      {
        display: 'flex',
        flexDirection: 'column',
        gridGap: 32,
      } : {
        component: SliderCarousel,
        parentCallback: callback,
      }

  const fetchingStatus = (
    <Box>
      {errorMessage ?
        <Box className={classes.error}>
          <WarningMessage
            color={WarningMessageColor.yellowLight}
            text="<strong><b>Sorry, we are having technical issues displaying our packages</b></strong></br></br>
             To proceed, please try the following:</br></br>
             Refresh the page</br></br>
             Try refreshing or clearing your browser cache</br></br>
             Make sure your device is up-to-date</br></br>
             Call us on <a href='tel:08081966262'>0808 196 6262</a> (Monday to Friday, 8am-9pm, weekends and bank holidays, 9am-7pm.)"
          />
        </Box> :
        <Box className={classes.spinner}>
          <LoadingSpinner/>
        </Box>}
    </Box>
  )

  return (
    <Wrapper id={anchor} gradient={background_gradient} container={false} horizontalPaddings={false} verticalPaddings>
      <Box component={Container} mb={valueProps ? 3 : 4}>
        <Box flexDirection="column" display="flex" alignItems="center">
          {heading && <Heading {...heading}/>}
          {offerBanner && offer_banner_position === 'top' &&
          <Box my={3}>
            <OfferBanner {...offer_banner!}/>
          </Box>}
          {tabs.length > 1 &&
          <Box
            width="100%"
            mx="auto"
            id="termToggler"
          >
            <PricingPackageTabs
              active={activeTabIndex!}
              onChange={handleChange}
              tabs={tabs}
            />
          </Box>}
          {offerBanner && offer_banner_position === 'middle' &&
          <Box mt={5}>
            <OfferBanner {...offer_banner!}/>
          </Box>}
        </Box>
      </Box>
      {valueProps &&
        <Box mb={2}>
          <ValueProps {...(value_props as ValuePropsProps)}/>
        </Box>}
      {((hybridPackages && hybridPackages.length > 0) || customer_segment === SegmentType.BUSINESS) ?
        <Box {...packagesProps}>
          {packagesFilteredCards}
        </Box> :
        fetchingStatus}
      {bundles && bundles?.bundles && Object.values(bundles?.bundles).length > 0 &&
        <Bundles
          can_be_selected={can_be_selected}
          on_click_package={on_click_bundle}
          bundles={bundles?.bundles}
          bundle_cta={package_cta}
          activeTerm={activeTermLength}
          mt={4}
          noContainer
        />}
      {offerBanner && offer_banner_position === 'bottom' &&
      <Box mt={6} mx={2}>
        <OfferBanner {...offer_banner!}/>
      </Box>}
      <PackagesFooter trustpilot={trustpilot} footnote={footnote} footnote_link={footnote_link}/>
    </Wrapper>
  )
}

export default Packages
