import { css } from 'linaria'
import theme from '../../../../styles/theme'

const classes = {
  container: css`
    margin-top: ${theme.spacing(2)}px;
    padding: 0 !important;
    position: relative;
    flex-direction: column-reverse;
    padding: 0 ${theme.spacing(2)}px;
    .MuiInputLabel-shrink {
      transform: translate(0) scale(1) !important;
      min-width: 180%;
    }
    .MuiContainer-root {
      padding: 0;
    }
    button[type=submit] {
      min-width: 165px;
      ${theme.breakpoints.down('xs')} {
          width: 100%;
      }
    }
    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      padding: 0 ${theme.spacing(5)}px;
    }
    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightMedium!}
    }
    .MuiGrid-grid-md-3 {
      max-width: 28%;
      flex-basis: 28%;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
      background-color: transparent !important;
    }
    h1 {
      font-size: ${theme.spacing(4.375)}px;
    }
    div[class*='messageWrapper'] {
      margin-bottom: ${theme.spacing(3)}px;
    }
  `,
  leftContainer: css`
    padding-top: ${theme.spacing(2)}px;
    div[class*='messageWrapper'] {
      position: sticky;
      top: ${theme.spacing(9)}px;
      z-index: 1;
      ${theme.breakpoints.up('md')} {
        top: ${theme.spacing(2)}px;
      }
    }
 `,
  backButton: css`
    display: flex;
    margin: ${theme.spacing(2)}px 0;
    color: ${theme.palette.primary.main};
    :active {
      color: ${theme.palette.primary.main};
    }
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!};
    }
    svg {
      position: relative;
      top: 2px;
      margin-right: ${theme.spacing(1)}px;
    }
  `,
  directDebit: css`
    display: flex;
    color: #707070;
    padding-top: ${theme.spacing(1)}px;
    .MuiGrid-root:first-child {
      align-self: flex-end;
    }
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!};
      padding-left: ${theme.spacing(3)}px;
      ${theme.breakpoints.up('sm')} {
        padding-left: 0;
      }
    }
    a {
      color: #707070;
      padding: 0;
    }
  `,
  mobileOrderButton: css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: white;
    padding: ${theme.spacing(2, 3)};
    ${theme.breakpoints.up('sm')} {
      display: flex;
      justify-content: center;
      .MuiButtonBase-root {
        max-width: 165px;
      }
    }
  `,
  iconAbsolute: css`
    position: absolute;
    top: -${theme.spacing(2)}px;
    right: 0;
    z-index: 2;
    ${theme.breakpoints.down('sm')} {
      width: ${theme.spacing(9.6)}px;
    }
  `,
  positionRelative: css`
    position: relative;
  `,
}

export default classes
