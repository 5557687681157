import React, { useEffect } from 'react'
import {
  Typography, Grid,
} from '@material-ui/core'
import { ContainerWithCurve } from '../../../../../../styles/ContainerWithCurve'
import classes from './PersonalisedUnit.styles'
import { useOrderContext } from '../../../../OrderContext'
import { getOrderProducts } from '../../../../../../utils/getOrderProducts'
import ProductCopyWithText from './ProductCopyWithText'
import { ContainerWithPadding } from '../../../../../../styles/ContainerWithPadding'
import { HobsProduct } from '../../../../../../api/Packages'

export interface ProductInfoProps {
  icon: string;
  body: string;
  service_type: string;
}

export interface PersonalisedUnitProps {
  thanks_message: string;
  order_message: string;
  small_print_copy?: string;
  anchor_ref?: string;
  subtitle: string;
  icon_with_product_info: ProductInfoProps[];
}

const PersonalisedUnit = ({
  thanks_message,
  order_message,
  small_print_copy,
  anchor_ref,
  subtitle,
  icon_with_product_info,
}: PersonalisedUnitProps) => {
  const { options: {
    broadband, bundle, tv, voice, wier, vas, data, accountId,
  } } = useOrderContext()

  const orderProducts = getOrderProducts({
    broadband,
    bundle,
    tv,
    voice,
    wier,
    vas,
  })

  useEffect(() => {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <section id={anchor_ref} className={classes.wrapper}>
      <ContainerWithCurve className={classes.curveContainer}>
        <ContainerWithPadding className={classes.wrapperInner}>
          <Typography align="center" variant="h1">{thanks_message} {data.firstName}</Typography>
          <Typography align="center" variant="h2">{order_message}<br/>{`Order No. ${accountId}`}</Typography>
          <Typography align="center" variant="h3">{small_print_copy}</Typography>
        </ContainerWithPadding>
      </ContainerWithCurve>
      <div className={classes.subTitle}>
        <div>
          <Typography variant="h4">{subtitle}</Typography>
        </div>
      </div>
      <Grid container justify="center" className={classes.orderInfo}>
        {orderProducts.map((product: HobsProduct, i: number) => {
          return (
            <ProductCopyWithText product={product} productsInfo={icon_with_product_info} key={i}/>
          )
        })}
      </Grid>
    </section>
  )
}

export default PersonalisedUnit

