import {
  useEffect,
} from 'react'
import { JsonObject } from 'type-fest'
import { experianStore } from '../../sessionStorage'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useOrderContext } from '../NewOrderFlow/OrderContext'

export interface ExperianOptions extends JsonObject, ExperianProps {}
export interface ExperianProps {
  email_validation: boolean;
  email_validation_cases: any;
  email_error: string;
  phone_validation: boolean;
  phone_validation_cases: any;
  phone_error: string;
}

export default function ExperianValidation({
  email_validation,
  email_validation_cases,
  email_error,
  phone_validation,
  phone_validation_cases,
  phone_error,
}: ExperianProps) {
  const data = {
    email_validation,
    email_validation_cases,
    email_error,
    phone_validation,
    phone_validation_cases,
    phone_error,
  } as ExperianProps

  const {
    options,
  } = useOrderContext()

  experianStore.set({
    ...data,
  })

  useEffect(() => {
    if (!email_validation) {
      marketingEvents.simpleEvent('experian_validation_disabled_email', options)
    }

    if (!phone_validation) {
      marketingEvents.simpleEvent('experian_validation_disabled_phone', options)
    }
  }, [])

  useEffect(() => {
    return () => {
      experianStore.delete()
    }
  }, [])

  return true
}
