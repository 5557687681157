import React from 'react'
import {
  Link,
  Typography,
} from '@material-ui/core'
import { BlogPost } from '../../../utils/blog'
import Tile from '../Tile/Tile'
import theme, { COLORS } from '../../../styles/theme'
import { css } from 'linaria'
const classes = {
  link: css`
    display: block;
    text-align: center;
    padding: 10px 20px;
    border: 2px solid ${COLORS.secondary};
    border-radius: 9999px;
    font-size: 1.125rem;
    font-weight: ${theme.typography.fontWeightBold!};
    position: relative;
    overflow: hidden;
    &:hover{
      background-color: ${COLORS.gray1};
    }
    &::after {
      content: '';
      position: absolute;
      background: ${COLORS.secondary};
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center;
      opacity: 0;
      pointer-events: none;
      transition: transform 0.3s ease, opacity 0.3s ease;
    }
    &:active::after {
      transform: scale(2);
      opacity: 0.3;
    }
  `,
}

export default function BlogTile({
  featured_image, slug, summary, title, color,
}: BlogPost) {
  return (
    <Tile featured_image={featured_image} title={title}>
      <Typography style={theme.typography.h4} color={color ? color : 'primary'} component="h4" variant="h1">{title}</Typography>
      <Typography variant="body1">{summary}</Typography>
      <Link underline="none" color="secondary" className={classes.link} href={`/community-connect/blog/${slug}`}>Read More</Link>
    </Tile>
  )
}
