import React, {
  ReactNode, useContext, useEffect, useState,
} from 'react'
import { JsonObject } from 'type-fest'
import { generalStore } from '../../sessionStorage'
import { Product } from '../../utils/commonInterfaces'

export interface GeneralOptions extends JsonObject {
  showStickyCheckAvailability: boolean;
  warningMessageType?: string;
  closedWarningMessageType?: string;
  eligibleForHiddenOffers: boolean;
  visibleProducts: Product[];
  closedBannerWithIcon: boolean;
  activeSpeedTabMobile?: number;
  urlPath?: string;
  sprn?: number;
  alternativePhone?: boolean;
  installationBoroughs?: Array<string>;
  rescheduleBoroughs?: Array<string>;
  trustpilotWidgets?: Array<string>;
  providers?: Array<string>;
  trustpilotId?: string;
  borough?: string | null;
  customerCategory: string;
  customerSegment: string;
  defaultRafId?: string;
}

export const INITIAL_GENERAL_OPTIONS: GeneralOptions = {
  showStickyCheckAvailability: true,
  eligibleForHiddenOffers: false,
  visibleProducts: [],
  closedBannerWithIcon: false,
  activeSpeedTabMobile: 1,
  urlPath: undefined,
  sprn: undefined,
  alternativePhone: undefined,
  installationBoroughs: undefined,
  rescheduleBoroughs: undefined,
  providers: undefined,
  trustpilotWidgets: undefined,
  trustpilotId: undefined,
  borough: undefined,
  customerCategory: 'RES',
  customerSegment: 'RESIDENTIAL',
  defaultRafId: '',
}

interface GeneralContextType {
  generalOptions: GeneralOptions;
  setGeneralOptions: React.Dispatch<React.SetStateAction<GeneralOptions>>;
}

const GeneralContext = React.createContext<GeneralContextType | null>(null)

export function useGeneralContext() {
  const context = useContext(GeneralContext)

  if (!context) {
    throw new Error('Ensure you use inside GeneralContext Provider')
  }

  return context
}

export function GeneralContextProvider({ children }: { children: ReactNode }) {
  const [
    generalOptions,
    setGeneralOptions,
  ] = useState<GeneralOptions>(INITIAL_GENERAL_OPTIONS)

  useEffect(() => {
    async function fetchSavedGeneralContext() {
      const savedGeneralContext = await generalStore.get()

      if (savedGeneralContext) {
        setGeneralOptions(state => {
          return ({
            ...state,
            ...savedGeneralContext,
          })
        })
      }
    }

    fetchSavedGeneralContext()
  }, [])

  useEffect(() => {
    async function persistGeneralState() {
      if (JSON.stringify(generalOptions) === JSON.stringify(INITIAL_GENERAL_OPTIONS)) {
        return
      }

      await generalStore.set({
        ...generalOptions,
      })
    }

    persistGeneralState()
  }, [generalOptions])

  return (
    <GeneralContext.Provider value={{
      generalOptions,
      setGeneralOptions,
    }}
    >
      {children}
    </GeneralContext.Provider>
  )
}
