/* eslint complexity: ["error", 100] */
import React, {
  useEffect, useState, useContext,
} from 'react'
import { useRouter } from 'next/router'
import {
  useForm, FormContext, Controller,
} from 'react-hook-form'
import {
  Typography, Grid, Select, MenuItem, Button, CircularProgress, Box, Hidden, useMediaQuery,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import moment from 'moment'
import { cx } from 'linaria'
import classes from './StillInContractNotInModal.styles'
import PersonalDetails, { GridConfig } from '../PersonalDetails/PersonalDetails'
import createCRMApiClient from '../../api/CRMApi'
import logger from '../../utils/logger'
import { triggerValuableLeadEvent } from '../../utils/triggerValuableLeadEvent'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import { getFourYearsForward } from '../../utils/getFourYearsForward'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import { formatInstallationAddress } from '../NewOrderFlow/steps/CheckoutStep/InstallationSection/InstallationSection'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import { CtaProps } from '../../utils/commonProps'
import StyledButton from '../StyledButton/StyledButton'
import theme from '../../styles/theme'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'
import getStepUrl from '../NewOrderFlow/getStepUrl'
import { SegmentType } from '../../utils/commonEnums'

export interface StillInContractNotInModalProps {
    title?: string;
    subtitle?: string;
    subtitle_mobile_only?: string;
    body_title?: string;
    body_content?: string;
    cta?: CtaProps;
    button_text?: string;
    offer_box_title?: string;
    offer_box_body?: string;
    offer_box_icon?: MediaImageProps;
    campaign_id: string;
    providers: string;
    success_slug: string;
    success_submit_message_title?: string;
    success_submit_message_subtitle?: string;
    consent_text?: string;
    promo_code_to_be_applied?: string;
  }

  interface FormValues {
    firstName: string;
    lastName: string;
    address: string;
    sprn: string;
    email: string;
    phone: string;
    month: string;
    year: string;
    provider: string;
  }

export default function StillInContractNotInModal({
  button_text,
  title,
  subtitle,
  subtitle_mobile_only,
  body_title,
  body_content,
  cta,
  success_submit_message_title,
  success_submit_message_subtitle,
  campaign_id,
  providers,
  offer_box_title,
  offer_box_body,
  offer_box_icon,
  success_slug,
  consent_text,
  promo_code_to_be_applied,
}: StillInContractNotInModalProps) {
  const {
    options, setOptions,
  } = useOrderContext()

  const [
    showSuccessSubmitMessage,
    setShowSuccessSubmitMessage,
  ] = useState(false)

  const [
    applyedOffer,
    setApplyedOffer,
  ] = useState(false)

  const [
    offerDeclined,
    setOfferDeclined,
  ] = useState(false)

  const [
    loading,
    setLoading,
  ] = useState<boolean>(false)

  const [
    providerError,
    setProviderError,
  ] = useState<boolean>(false)

  const [
    currentMonth,
    setCurrentMonth,
  ] = useState('')

  const [
    currentYear,
    setCurrentYear,
  ] = useState('')

  const [
    monthError,
    setMonthError,
  ] = useState<boolean>(false)

  const [
    yearError,
    setYearError,
  ] = useState<boolean>(false)

  const [
    isLastMonth,
    setIsLastMonth,
  ] = useState<boolean>(true)

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const { premise } = useContext(PremiseContext)

  const router = useRouter()

  const gridConfig: GridConfig = {
    firstName: { md: 12 },
    lastName: { md: 12 },
    email: { md: 12 },
    phone: { xs: 12 },
    spacing: 1,
  }

  const months = moment.months()
  const providersArray = providers ? providers.split('|') : []
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    handleSubmit, control, register, watch,
  } = methods

  const watchMonth = watch('month')
  const watchYear = watch('year')
  const watchProvider = watch('provider')

  useEffect(() => {
    setProviderError(false)
  }, [watchProvider])

  useEffect(() => {
    setMonthError(false)
    if (isLastContractMonth(currentMonth, currentYear)) {
      setIsLastMonth(true)
    } else {
      setIsLastMonth(false)
    }
  }, [
    watchMonth,
    currentMonth,
  ])

  useEffect(() => {
    setYearError(false)
    if (isLastContractMonth(currentMonth, currentYear)) {
      setIsLastMonth(true)
    } else {
      setIsLastMonth(false)
    }
  }, [
    watchYear,
    currentYear,
  ])

  const getMonthFromString = (mon: string) => {
    const d = Date.parse(mon + '1, 2012')
    if (!isNaN(d)) {
      return new Date(d)
        .getMonth() + 1
    }

    return -1
  }

  const isLastContractMonth = (month: string, year: string) => {
    const currentMonth =
      getMonthFromString(moment()
        .format('MMMM'))
    const currentYear = Number(moment()
      .format('YYYY'))
    const enteredMonth = getMonthFromString(month)
    const enteredYear = Number(year)
    return enteredMonth - currentMonth < 2 && enteredYear === currentYear
  }

  const applyingOffer = () => {
    setApplyedOffer(true)

    setOptions({
      ...options,
      promoToApply: promo_code_to_be_applied!,
    })
  }

  const onSubmit = handleSubmit(async ({
    firstName, lastName, email,
    phone, month, year, provider,
  }) => {
    setLoading(true)

    if (!premise) {
      logger.error('premise not found', 'premise not found')
      setLoading(false)
      return
    }

    if (provider === 'Your current provider') {
      setProviderError(true)
      setLoading(false)
      return
    }

    if (currentMonth === 'Month') {
      setMonthError(true)
      setLoading(false)
      return
    }

    if (currentYear === 'Year') {
      setYearError(true)
      setLoading(false)
      return
    }

    const client = createCRMApiClient()

    let contractEndDate = ''
    if (currentYear && currentYear !== 'Year') {
      let dateString = year

      if (currentMonth && currentMonth !== 'Month') {
        const monthNumber = getMonthFromString(month)
        const monthNumberString = ('0' + Number(monthNumber)).slice(-2)
        dateString = `${year}-${monthNumberString}-01T00:00:00.000Z`
      }

      contractEndDate = dateString.toString()
    }

    const addressSplited = formatInstallationAddress(premise.address)
      .split(',')

    const params = {
      address: {
        sprn: String(premise.sprn),
        pinCode: addressSplited.slice(-1)[0].trim(),
        addressLine1: addressSplited[0].trim(),
        addressLine2: addressSplited.length === 4 ? addressSplited[1].trim() : '',
        addressLine3: addressSplited.length === 5 ? addressSplited[2].trim() : '',
        town: addressSplited.length === 4 ? addressSplited[2].trim() : addressSplited[1].trim(),
        city: addressSplited.length === 4 ? addressSplited[2].trim() : addressSplited[1].trim(),
        stateOrProvince: premise.borough,
        totalAddress: premise.address,
      },
      firstName,
      lastName,
      contractEndDate,
      provider,
      phone,
      email,
    }

    const result = await client.hobsLeads.registerInterest(params)

    if (result.success === false || 'error' in result || result.code === 'SUPPLIER_FAULT') {
      logger.error(result.message, result.error)
      return
    }

    triggerValuableLeadEvent(premise)
    marketingEvents.endOfContractSubmit()

    const RegisterInterestData = {
      premise: premise!,
      firstName: firstName?.toLocaleLowerCase(),
      lastName: lastName?.toLocaleLowerCase(),
      emailAddress: email?.toLocaleLowerCase(),
      mobileNumber: phone,
      segment: SegmentType.RESIDENTIAL,
    }

    marketingEvents.registerInterestFormSubmit({
      eventName: 'register_interest_submitted',
      options,
      ...RegisterInterestData,
    })

    if (applyedOffer) {
      const url = `${getStepUrl('broadband-bundles', premise.sprn)}`
      router.push(success_slug ? success_slug : url)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    } else {
      setShowSuccessSubmitMessage(true)
    }
  })

  const renderContentOnLeftSide = () => {
    return (
      <Box mt={4} pt={3} className={classes.underline}>
        <Box pb={1}>
          <Typography variant="h4" color="primary">{body_title}</Typography>
        </Box>
        {body_content &&
        <Typography variant="body1" color="primary">{body_content}</Typography>}
        {cta?.text &&
        <Box pt={2}>
          <StyledButton
            color="secondary"
            variant="outlined"
            size="medium"
            minWidth={36}
            isFullWidth={isMobile}
            isModal={cta.modal}
            url={cta.url}
            order_flow_step={cta.order_flow_step}
          >
            {cta.text}
          </StyledButton>
        </Box>}
      </Box>
    )
  }

  const renderOfferBox = () => {
    return (
      <Grid>
        {offer_box_title && !offerDeclined &&
        <Grid container className={classes.blueOfferBox}>
          <>
            <Grid item xs={2} sm={1}>
              <MediaImage
                src={offer_box_icon!.src}
                mobile_src={offer_box_icon!.src}
                alt={offer_box_icon!.alt}
                width={offer_box_icon!.width}
                height={offer_box_icon!.height}
              />
            </Grid>
            <Grid item container xs={10} sm={11}>
              {applyedOffer ?
                <Typography variant="h5">Offer applied</Typography> :
                <Typography variant="h5">{offer_box_title}</Typography>}
              {applyedOffer ?
                <Box py={1}>
                  <Typography variant="overline">Continue below to sign up with your first month free.</Typography>
                </Box> :
                <>
                  <Box py={1}>
                    <Typography variant="overline">{offer_box_body}</Typography>
                  </Box>
                  <Grid item container direction={isMobile ? 'column' : 'row'}>
                    <Box marginRight={1} marginY={isMobile ? '5px' : 0}>
                      <Button
                        onClick={applyingOffer}
                      >
                        <Typography variant="button">Apply offer</Typography>
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => setOfferDeclined(true)}
                      >
                        <Typography variant="button">No thanks</Typography>
                      </Button>
                    </Box>
                  </Grid>
                </>}
            </Grid>
          </>
        </Grid>}
      </Grid>
    )
  }

  return (
    <SectionWithMargin>
      <ContainerWithPadding>
        <Grid container>
          <Grid item md={showSuccessSubmitMessage ? 12 : 6}>
            <Box marginRight={{
              xs: 0,
              md: 19,
            }}
            >
              {title &&
                <Box pb={{
                  xs: 1,
                  sm: 2,
                }}
                >
                  <Typography variant={isMobile ? 'h2' : 'h1'} color="primary">{showSuccessSubmitMessage ? success_submit_message_title : title}</Typography>
                </Box>}
              {subtitle &&
                <Typography variant="body1" color="textPrimary">{showSuccessSubmitMessage ? success_submit_message_subtitle : subtitle}</Typography>}
              {subtitle_mobile_only && !showSuccessSubmitMessage &&
                <Hidden mdUp>
                  <Box pt={1}>
                    <Typography variant="body1" color="textPrimary">{subtitle_mobile_only}</Typography>
                  </Box>
                </Hidden>}
              {body_title && !isMobile && !showSuccessSubmitMessage &&
              renderContentOnLeftSide()}
            </Box>
          </Grid>
          {showSuccessSubmitMessage ?
            renderContentOnLeftSide() :
            <Grid item md={6}>
              <FormContext {...methods}>
                <Box className={classes.contractForm}>
                  <Grid container>
                    <Grid item>
                      <Box pb={2}>
                        <form onSubmit={onSubmit}>
                          <Grid container>
                            <PersonalDetails
                              showConsent={false}
                              showLandlineField={false}
                              showConfirmEmailField={false}
                              showConsentText
                              consentText={consent_text}
                              campaignId={campaign_id}
                              gridConfig={gridConfig}
                              hideAddress
                            >
                              <Grid direction="column" container>
                                <Typography className={cx(classes.inputLabel, providerError && classes.errorLabel)}>Who is your existing service provider?*</Typography>
                                <Grid direction="row" container>
                                  <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name="provider"
                                    defaultValue="Your current provider"
                                    renderValue={(selected: string) => {
                                      if (!selected) {
                                        return 'Provider'
                                      }

                                      return selected
                                    }}
                                    as={
                                      <Select
                                        className={cx(classes.selectClass, (watchProvider && watchProvider !== 'Provider') && classes.activeSelect, providerError && classes.errorInput)}
                                        IconComponent={KeyboardArrowDownIcon}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          transformOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          getContentAnchorEl: null,
                                        }}
                                        disableUnderline
                                        inputProps={{
                                          classes: {
                                            icon: cx(classes.icon, (watchProvider && watchProvider !== 'Provider') && classes.activeIcon),
                                          },
                                        }}
                                      >
                                        {providersArray.map((provider) => (
                                          <MenuItem key={provider} value={provider} className={classes.option}>{provider}</MenuItem>
                                        ))}
                                      </Select>
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid direction="column" container>
                                <Typography className={cx(classes.inputLabel, (monthError || yearError) && classes.errorLabel)}>When do you think your current contract ends?*</Typography>
                                <Grid direction="row" container spacing={2}>
                                  <Grid item xs={12} sm={4}>
                                    <Controller
                                      control={control}
                                      name="month"
                                      rules={{ required: true }}
                                      defaultValue="Month"
                                      renderValue={(selected: string) => {
                                        if (!selected) {
                                          return 'Month'
                                        }

                                        setCurrentMonth(selected)
                                        return currentMonth
                                      }}
                                      as={
                                        <Select
                                          className={cx(classes.selectClass, (watchMonth && watchMonth !== 'Month') && classes.activeSelect, monthError && classes.errorInput)}
                                          IconComponent={KeyboardArrowDownIcon}
                                          MenuProps={{
                                            anchorOrigin: {
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            },
                                            transformOrigin: {
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                          }}
                                          disableUnderline
                                          inputProps={{
                                            classes: {
                                              icon: cx(classes.icon, (watchMonth && watchMonth !== 'Month') && classes.activeIcon),
                                            },
                                          }}
                                        >
                                          {months.map((month) => (
                                            <MenuItem key={month} value={month} className={classes.option}>{month}</MenuItem>
                                          ))}
                                        </Select>
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <Controller
                                      control={control}
                                      name="year"
                                      rules={{ required: true }}
                                      defaultValue="Year"
                                      renderValue={(selected: string) => {
                                        if (!selected) {
                                          return 'Year'
                                        }

                                        setCurrentYear(selected)
                                        return currentYear
                                      }}
                                      as={
                                        <Select
                                          className={cx(classes.selectClass, (watchYear && watchYear !== 'Year') && classes.activeSelect, yearError && classes.errorInput)}
                                          MenuProps={{
                                            anchorOrigin: {
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            },
                                            transformOrigin: {
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                          }}

                                          IconComponent={KeyboardArrowDownIcon}
                                          disableUnderline
                                          inputProps={{
                                            classes: {
                                              icon: cx(classes.icon, (watchYear && watchYear !== 'Year') && classes.activeIcon),
                                            },
                                          }}
                                        >
                                          {getFourYearsForward()
                                            .map((year) => (
                                              <MenuItem key={year} {...register()} value={year} className={classes.option}>
                                                {year}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {isLastMonth &&
                              renderOfferBox()}
                            </PersonalDetails>
                            <Grid container className={classes.submitClasses}>
                              <Grid item lg={6} xs={12}>
                                <Button type="submit" fullWidth variant="contained" color="primary" disableElevation>
                                  <Typography noWrap>{button_text}
                                    {(loading && !providerError) && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {body_title && isMobile &&
              renderContentOnLeftSide()}
              </FormContext>
            </Grid>}
        </Grid>
      </ContainerWithPadding>
    </SectionWithMargin>
  )
}
