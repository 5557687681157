import React from 'react'
import {
  Box,
  Hidden,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import classes from './SpeedTabs.styles'
import {
  RadioButtonUnchecked, RadioButtonChecked,
} from '@material-ui/icons'

export interface TabProps {
  speed_number: number | string;
  speed_text: string;
}

export interface SpeedTabsProps {
  anchor_ref?: string;
  tabs: TabProps[];
  activeSpeedTab?: number;
  handleClick: (i: number) => void;
  hidden?: boolean;
  modal_display?: boolean;
  mb?: number;
}
const SpeedTabs = ({
  anchor_ref, tabs, activeSpeedTab, handleClick, hidden = true, modal_display, mb,
}: SpeedTabsProps) => {
  const handleChange = (index: number) => {
    handleClick(index)
  }

  return (
    <Hidden smUp={hidden}>
      <Box
        id={anchor_ref}
        {...modal_display && { className: classes.container }}
        {...(!modal_display && {
          maxWidth: '350px',
          mx: 'auto',
        })}
        mb={mb}
      >
        <div className={classes.tabsWrapper}>
          {tabs.map((tab, index) => {
            return (
              <FormControlLabel
                key={index}
                className={classes.checkBox}
                control={
                  <Checkbox
                    icon={<RadioButtonUnchecked/>}
                    checkedIcon={<RadioButtonChecked/>}
                    checked={activeSpeedTab === index}
                    onChange={() => handleChange(index)}
                    name={`speedTab${index}`}
                    color="default"
                  />
                }
                label={(
                  <Box className={classes.speedText}>
                    <span>{tab.speed_number.toString()}</span>
                    <span>{tab.speed_text}</span>
                  </Box>
                )}
              />
            )
          })}
        </div>
      </Box>
    </Hidden>
  )
}

export default SpeedTabs
