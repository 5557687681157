import { OrderOptions } from '../../components/NewOrderFlow/OrderContext'
import { isProduct } from '../isProduct'
import { getProductDataForFacebook } from '../getProductDataForFacebook'
import {
  awinTrack, legacyAwinTrack,
} from './awinTrack'
import {
  getBasePrice, getDiscountPrice, getInstallationCostFromObj, getInstallationObj, getMonthlyCost, getProductTerm, getSpeed, getStandardPrice, getTotalInstallationCost,
} from '../getProductDetails'
import { HobsProduct } from '../../api/Packages'
import { PremiseDetail } from '../../api/Addresses'
import { HobsServiceType } from '../../api/Products'
import { GeneralOptions } from '../../components/GeneralContext/GeneralContext'
import { NextRouter } from 'next/router'
import { SegmentType } from '../commonEnums'

type DefaultFacebookEvent = 'Purchase' | 'Schedule' | 'ViewContent' | 'CompleteRegistration'

function isDefaultFacebookEvent(eventName: string | DefaultFacebookEvent): eventName is DefaultFacebookEvent {
  const events = [
    'Purchase',
    'Schedule',
    'ViewContent',
    'CompleteRegistration',
  ]

  return events.includes(eventName)
}

function facebookTrack(eventName: DefaultFacebookEvent | string, data?: unknown) {
  if (window && window.fbq) {
    if (isDefaultFacebookEvent(eventName)) {
      window.fbq('track', eventName, data)
    } else {
      window.fbq('trackCustom', eventName, data)
    }
  }
}

interface GoogleEcommercePurchase {
  actionField: {
    id: string;
    revenue: string;
    coupon?: string;
  };
  products: Array<{
    id: string;
    name?: string;
    price: string;
    brand: string;
    category: string;
    variant: string;
    quantity: number;
  }>;
}
interface GoogleTrackProps {
  eventName?: string;
  value?: unknown;
  ecommerce?: {
    checkout?: {
      actionField: {
        step: number;
        option?: number;
      };
    };
    purchase?: GoogleEcommercePurchase;
  };
}
interface GoogleTrackGA4Props {
  eventName: string;
  options: OrderOptions;
  params?: {};
  ecommerceParams?: {};
}

interface OptionsProps {
  options: OrderOptions;
}

interface RegisterInterestFormProps extends OptionsProps {
  eventName: string;
  premise: PremiseDetail;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileNumber: string;
  options: OrderOptions;
  segment: SegmentType;
}
interface CtaProps extends OptionsProps {
  name?: string;
  location?: string;
  url?: string;
  modal?: boolean;
  router?: NextRouter;
}

interface ErrorDetailedProps extends OptionsProps {
  errorCode: string;
  errorName: string;
  errorMessage: string;
  reservationId: string;
  cartInstanceIdentifier: string;
  timeslotId: string;
  packageId: string;
  sprn: string;
}

interface AppointmentProps extends OptionsProps {
  eventName: string;
  accountNumber?: string;
  appointmentWindowId?: string;
  date?: string;
  borough?: string | null;
  broadbandProduct?: string;
  sprn?: number;
  orderId?: string;
}

// GoogleTrackEvent is old UA code, we only use googleTrackGA4 now
function googleTrackEvent(eventName: string, value?: unknown) {
  googleTrack({
    eventName,
    value,
  })
}

export function googleTrack({
  eventName, value, ecommerce,
}: GoogleTrackProps) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      value,
      ecommerce,
    })
  }
}

const googleTrackGA4 = ({
  eventName, options, params, ecommerceParams,
}: GoogleTrackGA4Props) => {
  if (window && window.dataLayer) {
    const dataLayer = window.dataLayer as any[]
    const url = { url: window.location.href }
    const platform = { platform: options.platform }
    ecommerceParams && dataLayer.push({ ecommerce: null })
    const GA4Object = {
      event: eventName,
      ...(ecommerceParams && { ecommerce: ecommerceParams }),
      ...params,
      ...url,
      ...platform,
    }

    window.dataLayer.push(GA4Object)
  }
}

const getAreaCode = (premise: PremiseDetail) => premise.postcode && premise.postcode.split(' ')[0]
const getOnFootprint = (premise: PremiseDetail) => premise.is_in_london && premise.status === 80
const getPremiseAddress = (premise: PremiseDetail, segment: SegmentType, footprint?: boolean) => ({
  areaCode: getAreaCode(premise),
  postcode: premise.postcode,
  fullAddress: premise.address,
  sprn: premise.sprn,
  segment,
  ...premise.borough && { borough: premise.borough },
  ...footprint && { footprint: getOnFootprint(premise) },
})

const discountAmount = (product: HobsProduct) => {
  const standardPrice = Number(getStandardPrice(product))
  const discountPrice = Number(getDiscountPrice(product))

  return discountPrice ? (standardPrice - discountPrice).toFixed(2)
    .toString() : '0.00'
}

const getEcommerceItems = (products: HobsProduct[]) => {
  products.forEach(product => {
    let installationObj
    installationObj = getInstallationObj(product, !isBroadbandOrBundle(product))
    if (installationObj) {
      installationObj = {
        ...installationObj,
        service_type: 'installation',
      }
      products.push(installationObj)
    }
  })

  return products && Object.values(products)
    .map((product: HobsProduct, index: number) => {
      const getPrice = (product: any) => product.service_type === 'installation' ? getInstallationCostFromObj(product) : getBasePrice(product)
      const speed = getSpeed(product, 'DOWNLOAD_SPEED')
      return {
        item_id: product.productOfferingId,
        item_name: product.label,
        discount: discountAmount(product),
        index,
        item_category: product.service_type,
        contract: getProductTerm(product),
        price: getPrice(product),
        quantity: 1,
        ...(speed && { speed }),
      }
    })
    .filter(product => product?.item_id)
}

export const isBroadbandOrBundle = (product: HobsProduct) => {
  const { service_type: productServiceType } = product || {}
  return productServiceType === HobsServiceType.BROADBAND || productServiceType === HobsServiceType.BUNDLE
}

const getEcommerce = (options: OrderOptions, product?: HobsProduct, params?: {}) => {
  const {
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
    promo,
    discount,
  } = options

  let products: HobsProduct[] = []

  if (product) {
    products.push(product)
  } else {
    products = [
      broadband,
      bundle,
      ...(isBroadbandOrBundle(product!) ? [] : [
        voice!,
        tv!,
        wier!,
        vas!,
      ]),
    ].filter(Boolean)
  }

  const productsWithKeys = Object.fromEntries(products.map((p: any) => [
    p?.service_type! || p?.serviceType?.toLowerCase(),
    p,
  ]))

  const productsWithKeysAndDiscount = {
    ...productsWithKeys,
    discount,
  }

  const monthlyCost = getMonthlyCost(productsWithKeysAndDiscount) + getTotalInstallationCost(productsWithKeys)
  const parsedMonthlyCost = monthlyCost.toFixed(2)
  const vatRate = 0.2
  const getTax = (price: number) => (price * vatRate).toFixed(2)

  return {
    currency: 'GBP',
    value: parsedMonthlyCost,
    tax: getTax(monthlyCost),
    items: getEcommerceItems(products),
    ...(promo?.productOfferingId && { coupon: promo?.productOfferingId }),
    ...params,
  }
}

const marketingEvents = {
  registerInterestFormCompleted() {
    googleTrackEvent('register-interest-residential-form_completed')
  },
  threeGigaRegisterInterestFormSubmitted() {
    googleTrackEvent('register-interest-residential_3GigaFast_lead-submitted')
  },
  checkedAddress(premise: PremiseDetail) {
    facebookTrack('FindLocation', { sprn: premise.sprn })
    googleTrackEvent('address-lookup_checked-address')
  },
  foundAddress(action: 'order' | 'none' | 'register_interest' | 'register_interest_early' | 'not_in_london') {
    facebookTrack('FoundAddress', { accepting: action })
    googleTrackEvent('address-lookup_found-address', action)
  },
  addressSubmit(premise: PremiseDetail, segment: SegmentType, options: OrderOptions) {
    googleTrackGA4({
      eventName: 'address_submit',
      options,
      params: getPremiseAddress(premise, segment, true),
    })
  },
  onFootprint(premise: PremiseDetail, segment: SegmentType, options: OrderOptions) {
    if (!getOnFootprint(premise)) {
      return
    }

    googleTrackGA4({
      eventName: 'onFootprint_address',
      options,
      params: getPremiseAddress(premise, segment),
    })
  },
  selectedAddress(premise: PremiseDetail, segment: SegmentType, options: OrderOptions) {
    googleTrackEvent('address-lookup_selected-address')
    googleTrackGA4({
      eventName: 'address_found',
      options,
      params: getPremiseAddress(premise, segment),
    })
  },
  initOrder() {
    googleTrackEvent('order-checkout_visited')
  },
  addToCart(product: HobsProduct) {
    googleTrackEvent(
      'order-checkout_product-selected',
      product?.productOfferingId,
    )
  },
  engageWithCart(eventName: string, product: HobsProduct, options: OrderOptions) {
    googleTrackGA4({
      eventName,
      options,
      ecommerceParams: getEcommerce(options, product),
    })
  },
  ecommerceEvent(eventName: string, options: OrderOptions) {
    googleTrackGA4({
      eventName,
      options,
      ecommerceParams: getEcommerce(options),
    })
  },
  selectInstallation(eventName: string, options: OrderOptions, date: string, time: string, numberDays?: number) {
    googleTrackGA4({
      eventName,
      options,
      params: {
        date,
        time,
        ...numberDays && { numberDays },
      },
    })
  },
  addToCartFacebook(products: any) {
    const facebookData = getProductDataForFacebook(products)
    facebookTrack('AddToCart', facebookData)
  },
  addContactInfo() {
    googleTrackEvent('order-checkout_contact-information-added')
  },
  completePayment() {
    googleTrackEvent('order-checkout_payment-information-added')
  },
  appointmentEvent({
    eventName,
    options,
    accountNumber,
    appointmentWindowId,
    date,
    borough,
    broadbandProduct,
    sprn,
    orderId,
  }: AppointmentProps) {
    googleTrackGA4({
      eventName,
      options,
      params: {
        accountNumber,
        borough,
        broadbandProduct,
        ...appointmentWindowId && { appointmentWindowId },
        ...date && { date },
        ...sprn && { sprn },
        ...orderId && { orderId },
      },
    })
  },

  // Used in old OrderFlow which is not used anymore
  async legacyOrderSubmitted(order: any, accountId: string) {
    const amount = order.product!.productPrices.reduce((p: any, x: any) => p + Number(x.discountAmount || x.price), 0)

    facebookTrack('Purchase', {
      value: amount,
      currency: 'GBP',
    })
    googleTrackEvent(
      'order-checkout_order-submitted',
      amount,
    )
    await legacyAwinTrack(order, accountId)
  },

  async orderSubmitted(order: OrderOptions, accountId: string, general: GeneralOptions) {
    if (!order.broadband && !order.bundle) {
      return
    }

    const {
      broadband, voice, tv, wier, vas, bundle, discount, promo,
    } = order

    const productCosts = getMonthlyCost({
      broadband,
      voice,
      tv,
      wier,
      vas,
      bundle,
      discount,
      promo,
    })

    const installationCosts = getTotalInstallationCost({
      broadband,
      voice,
      tv,
      wier,
      vas,
      bundle,
    })

    const amount = productCosts + installationCosts

    facebookTrack('Purchase', {
      value: amount,
      currency: 'GBP',
    })
    googleTrackEvent(
      'order-checkout_order-submitted',
      amount,
    )

    const products = [
      broadband,
      bundle,
      voice,
      tv,
      wier,
      vas,
    ].filter(product => isProduct(product)) as HobsProduct[]

    googleTrack(
      {
        eventName: 'checkout',
        ecommerce: {
          purchase: {
            actionField: {
              id: accountId,
              revenue: String(amount),
              coupon: order.promo?.productOfferingId,
            },
            products: products.map(mapProductToEcommerceProduct),
          },
        },
      })

    const {
      firstName, lastName, email, sprn,
    } = order?.data

    googleTrackGA4({
      eventName: 'purchase',
      options: order,
      params: {
        firstName: firstName?.toLocaleLowerCase(),
        lastName: lastName?.toLocaleLowerCase(),
        email: email?.toLocaleLowerCase(),
        sprn,
        borough: general.borough,
      },
      ecommerceParams: getEcommerce(order, undefined, {
        transaction_id: accountId,
        payment_type: 'Direct debit',
      }),
    })

    await awinTrack(order, accountId)
  },
  orderConfirmation() {
    googleTrackEvent('order-confirmation_visited')
  },
  discountCodeChecked(code: string) {
    googleTrackEvent('order-confirmation_discount-code-checked', code)
  },
  discountVoucherChecked(code: string) {
    googleTrackEvent('order-confirmation_voucher-code-checked', code)
  },
  initBooking(kind: 'order' | 'reschedule') {
    switch (kind) {
      case 'order':
        googleTrackEvent('booking-calendar_visited')
        break

      case 'reschedule':
        googleTrackEvent('reschedule-calendar_visited')
    }
  },
  schedule(kind: 'order' | 'reschedule') {
    facebookTrack('Schedule')

    switch (kind) {
      case 'order':
        googleTrackEvent('appointment-complete_visited')
        break

      case 'reschedule':
        googleTrackEvent('reschedule-complete_visited')
    }
  },
  pageView(slug: string) {
    facebookTrack('ViewContent', { slug })
  },
  // Currency values here don't map to actual currencies
  // they're used on facebook to differentiate the different types of leads
  registerInterestResidential() {
    facebookTrack('CompleteRegistration', {
      value: 1,
      currency: 'GBP',
    })
    googleTrackEvent('register-interest-residential_lead-submitted')
  },
  registerInterestPageLoad(premise: PremiseDetail, segment: SegmentType, options: OrderOptions) {
    googleTrackGA4({
      eventName: 'register_interest',
      options,
      params: {
        formName: 'register interest',
        ...getPremiseAddress(premise, segment),
      },
    })
  },
  registerInterestFormSubmit({
    eventName, options, premise, firstName, lastName, emailAddress, mobileNumber, segment,
  }: RegisterInterestFormProps) {
    googleTrackGA4({
      eventName,
      options,
      params: {
        formName: 'register interest',
        firstName,
        lastName,
        emailAddress,
        mobileNumber,
        ...getPremiseAddress(premise, segment),
      },
    })
  },
  registerInterestBusiness() {
    facebookTrack('CompleteRegistration', {
      value: 2,
      currency: 'GPB',
    })
    googleTrackEvent('register-interest-business_lead-submitted')
  },
  registerInterestPartner() {
    facebookTrack('CompleteRegistration', {
      value: 3,
      currency: 'GPB',
    })
    googleTrackEvent('register-interest-partner_lead-submitted')
  },
  registerInterestLandlord() {
    facebookTrack('CompleteRegistration', {
      value: 4,
      currency: 'GPB',
    })
    googleTrackEvent('register-interest-landlord_lead-submitted')
  },
  onCheckoutOption(step: number) {
    googleTrackEvent('checkout', {
      ecommerce: {
        checkout: {
          actionField: {
            step,
          },
        },
      },
    })
  },
  onCheckoutSection(section: string) {
    googleTrackEvent(`order-checkout_${section}-step`)
  },
  generatedReferralCode(referralCode: string) {
    googleTrackEvent('refer_code_generated', referralCode)
  },
  generatedReferralCustomer(referralId: string) {
    googleTrackEvent('refer_id_generated', referralId)
  },
  sharedReferralCode(socialMedia: string, referralCode: string) {
    googleTrackEvent('refer_link_shared', {
      socialMedia,
      referralCode,
    })
  },
  onFriendsPage(referralCode: string) {
    googleTrackEvent('on_friends_page', referralCode)
  },
  createValuableLead() {
    googleTrackEvent('valuable_lead_created')
  },
  closedSocialProofBanner() {
    googleTrackEvent('packagepage_socialproofbanner_closed')
  },
  clickedOnTelesalesNumber() {
    googleTrackEvent('telesales_call_checkout_header')
  },
  openCallMeBackModal() {
    googleTrackEvent('callmeback_popup_open')
  },
  callMeBackFormSubmitted() {
    googleTrackEvent('callmeback_submitted')
  },
  closeCallMeBackModal() {
    googleTrackEvent('callmeback_cart_popup_closed')
  },
  openAbandonedCartModal(trigger: string) {
    googleTrackEvent('abandoned-cart_opened', trigger)
  },
  abandonedCartFormSubmitted() {
    googleTrackEvent('abandon_cart_popup_submitted')
  },
  closeAbandonedCartModal() {
    googleTrackEvent('abandon_cart_popup_closed')
  },
  trackDaysUntilTimeslot(numberOfBusinessDays: number) {
    googleTrackEvent('first_installation', numberOfBusinessDays)
  },
  endOfContractSubmit() {
    googleTrackEvent('End_of_Contract_submit')
  },
  endOfContractClose() {
    googleTrackEvent('End_of_Contract_close')
  },
  simpleEvent(eventName: string, options: OrderOptions) {
    googleTrackGA4({
      eventName,
      options,
    })
  },
  error(options: OrderOptions, errorCode: string, errorName: string, errorMessage: string) {
    googleTrackGA4({
      eventName: 'error_tracking',
      options,
      params: {
        errorCode,
        errorName,
        errorMessage,
      },
    })
  },
  errorDetailed({
    options, errorCode, errorName, errorMessage, reservationId, cartInstanceIdentifier, timeslotId, packageId, sprn,
  }: ErrorDetailedProps) {
    googleTrackGA4({
      eventName: 'error_tracking_detailed',
      options,
      params: {
        errorCode,
        errorName,
        errorMessage,
        reservationId,
        cartInstanceIdentifier,
        timeslotId,
        packageId,
        sprn,
      },
    })
  },
  cta({
    name, router, url, modal, options,
  }: CtaProps) {
    const urlHasQueryParams = router?.asPath.includes('?')
    const currentSegment = (url: string) => {
      if (url.includes('/business')) {
        return 'Business'
      }

      if (url.includes('/landlord')) {
        return 'Landlord'
      }

      return 'Residential'
    }

    const parseName = (name: string) => {
      if (name?.includes('false,')) {
        return name.split('false,')[1]
      }

      return name
    }

    googleTrackGA4({
      eventName: 'cta_button_click',
      options,
      params: {
        ctaButtonName: parseName(name!),
        ctaButtonLocation: urlHasQueryParams ? router?.asPath.split('?')[0] : router?.asPath,
        ctaButtonURL: url,
        ctaButtonModal: modal || false,
        ctaButtonCategory: currentSegment(router?.asPath!),
        ctaButtonProduct: 'Broadband',
      },
    })
  },
  getReferralCode(options: OrderOptions, referralCode: string) {
    googleTrackGA4({
      eventName: 'referral_code',
      options,
      params: {
        referralCode,
      },
    })
  },
}

export default marketingEvents

function mapProductToEcommerceProduct(product: any): GoogleEcommercePurchase['products'][0] {
  return {
    id: product.productOfferingId,
    name: product.label,
    brand: 'Community Fibre',
    category: product.service_type,
    price: getBasePrice(product),
    variant: String(getProductTerm(product)),
    quantity: 1,
  }
}
