import React, {
  useEffect, useState,
} from 'react'
import {
  Box, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Typography,
} from '@material-ui/core'
import TextInput from '../TextInput/TextInput'
import {
  useForm, FormContext, Controller,
} from 'react-hook-form'
import classes from './ContactUsForm.styles'
import StyledButton from '../StyledButton/StyledButton'
import logger from '../../utils/logger'
import createCRMApiClient from '../../api/CRMApi'
import {
  ExperianEmail, ExperianPhone,
} from '../../api/Experian'
import { ExperianOptions } from '../ExperianValidation/ExperianValidation'
import { experianStore } from '../../sessionStorage'
import { PersonalDetailsFormData } from '../NewOrderFlow/steps/CheckoutStep/PersonalDetailsForm/PersonalDetailsForm'
import { arraysEqual } from '../../utils/arraysEqual'
import {
  specialCharactersSanitizer, handleKeyDown, handlePhoneChange,
  phoneRegex,
  nameRegex,
  addressRegex,
} from '../../utils'
import { cx } from 'linaria'

export interface ContactUsFormProps {
  title?: string;
  sub_title?: string;
}

const contactReasons = [
  'No Internet Connection',
  'Billing or Contract Query',
  'Technical Issue',
  'Appointment Query',
  'Business Query',
  'Voice or TV Products',
  'Upgrade or Renewals',
  'Moving Home',
  'Freeze Contract',
  'Account Transfer or Bereavement',
  'Change or Add Contact Details',
  'My account',
  'Referrals or Vouchers',
  'Complaint',
  'Cancellation Request',
  'Marketing Preferences',
  'Issue Not Listed',
]

export default function ContactUsForm(props: ContactUsFormProps) {
  const {
    title, sub_title,
  } = props

  const methods = useForm<PersonalDetailsFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const {
    handleSubmit, control, getValues, triggerValidation, formState,
  } = methods
  const { isSubmitting } = formState

  const [
    checked,
    setChecked,
  ] = useState<boolean>(false)

  const [
    error,
    setError,
  ] = useState<boolean>(false)

  const [
    selectValue,
    setSelectValue,
  ] = useState<string>('')

  const [
    customBox,
    setCustomBox,
  ] = useState<boolean>(false)

  const [
    requiredError,
    setRequiredError,
  ] = useState<boolean>(false)

  const [
    experianData,
    setExperianData,
  ] = useState<ExperianOptions | null>()

  useEffect(() => {
    (async () => {
      const response = await experianStore.get()
      if (response) {
        setExperianData(response)
      }
    })()
  }, [])

  useEffect(() => {
    if (selectValue === 'Issue Not Listed') {
      setCustomBox(true)
    } else {
      setCustomBox(false)
    }

    setRequiredError(false)
  }, [selectValue])

  useEffect(() => {
    const requiredFields: string[] = [
      'firstName',
      'lastName',
      'enquiryDetails',
      'yourEmailAddress',
      'contactNumber',
      'reasonForContacting',
    ]
    const completedFields: string[] = [...formState.dirtyFields]
    arraysEqual(completedFields, requiredFields)
  }, [getValues()])

  const triggerEmailVerification = () => {
    const { confirmEmail } = getValues()
    if (confirmEmail) {
      triggerValidation('confirmEmail')
    }
  }

  const validateExperianEmail = async (input: string) => {
    const client = createCRMApiClient()

    if (!experianData?.email_validation) {
      return
    }

    const validationResponse: ExperianEmail = await (async () => {
      try {
        return await client.experian.validateEmail(input)
      } catch (err) {
        return err.message
      }
    })()

    for (const el of experianData?.email_validation_cases) {
      if (validationResponse?.result?.confidence === el.confidence) {
        return
      }
    }

    return experianData?.email_error
  }

  const validateExperianPhone = async (input: string) => {
    const client = createCRMApiClient()

    if (!experianData?.phone_validation) {
      return
    }

    const validationResponse: ExperianPhone = await (async () => {
      try {
        return await client.experian.validatePhone(input)
      } catch (err) {
        return err.message
      }
    })()

    for (const el of experianData?.phone_validation_cases) {
      if (validationResponse?.result?.confidence === el.confidence) {
        return
      }
    }

    return experianData?.phone_error
  }

  const onSubmit = handleSubmit(async (data: any) => {
    if (!selectValue || selectValue === 'Please select a reason') {
      setRequiredError(true)
      return
    }

    setRequiredError(false)

    const client = createCRMApiClient()

    const {
      firstName,
      lastName,
      enquiryDetails,
      yourEmailAddress,
      addressLine_1,
      addressLine_2,
      addressLine_3,
      townOrCity,
      postcode,
      contactNumber,
      reasonForContacting,
      pleaseWriteYourReason,
    } = data

    const customerCheck: any = await client.contactUs.customerCheck(
      yourEmailAddress,
      contactNumber,
    )

    const accountId = customerCheck?.accountId || ''
    const description = `Name: ${firstName} ${lastName}\\nEmail Address: ${yourEmailAddress}\\nPhone Number: ${contactNumber}\\nReason: ${pleaseWriteYourReason || reasonForContacting}\\nAddress: ${addressLine_1},${addressLine_2},${addressLine_3},${townOrCity}\\nPostcode: ${postcode}\\nDescription: ${enquiryDetails.replace(/\r?\n|\r/g, ' ')}`

    const result: any = await client.contactUs.createWorkItem(
      accountId === '' ? 'RPA request' : reasonForContacting,
      firstName,
      lastName,
      yourEmailAddress,
      addressLine_1 ? addressLine_1 : '',
      addressLine_2 ? addressLine_2 : '',
      addressLine_3 ? addressLine_3 : '',
      townOrCity ? townOrCity : '',
      postcode ? postcode : '',
      contactNumber,
      description,
      accountId,
      customerCheck?.customerId || '',
    )

    if (result && result.statusList?.[0]?.statusDescription === 'SUCCESS') {
      location.assign('/thanks-for-your-request')
    } else {
      logger.error(result.message, result.error)
      setError(true)
    }
  })

  return (
    <FormContext {...methods}>
      <Container className={classes.wrapper}>
        <form onSubmit={onSubmit}>
          <Grid container item direction="column">
            <Box pt={6}>
              <Typography variant="h1" noWrap>
                {title}
              </Typography>
              <Typography variant="body2">{sub_title}</Typography>
            </Box>
            <Box pt={6}>
              <TextInput
                className={classes.fieldClass}
                gridWidth={12}
                required
                label="First Name"
                handleChange={specialCharactersSanitizer}
                validation={{
                  pattern: {
                    value: nameRegex,
                    message: 'Please use a valid name. Insert alphabetic characters only',
                  },
                  minLength: {
                    value: 1,
                    message: 'Minimum length is 3 characters. Please use a valid name.',
                  },
                  maxLength: {
                    value: 25,
                    message: 'Maximum length is 25 characters.  Please use a valid name.',
                  },
                  validate: (value) => value.trim().length > 0 || 'First Name is required and cannot be just spaces',
                }}
              />
            </Box>
            <Box pt={6}>
              <TextInput
                className={classes.fieldClass}
                gridWidth={12}
                required
                label="Last Name"
                handleChange={specialCharactersSanitizer}
                validation={{
                  pattern: {
                    value: nameRegex,
                    message: 'Please use a valid name. Insert alphabetic characters only',
                  },
                  minLength: {
                    value: 1,
                    message: 'Minimum length is 3 characters. Please use a valid name.',
                  },
                  maxLength: {
                    value: 25,
                    message: 'Maximum length is 25 characters. Please use a valid name.',
                  },
                  validate: (value) => value.trim().length > 0 || 'Last Name is required and cannot be just spaces',
                }}
              />
            </Box>
            <Box pt={6}>
              <TextInput
                className={classes.fieldClass}
                gridWidth={12}
                required
                label="Your email address"
                placeholder="me@example.co.uk"
                type="email"
                handleChange={triggerEmailVerification}
                validation={{ validate: validateExperianEmail }}
              />
            </Box>
            <Box pt={6}>
              <TextInput
                className={classes.fieldClass}
                gridWidth={12}
                required
                label="Contact Number"
                id="contactNumber"
                type="tel"
                inputProps={{
                  maxLength: 11,
                  autoComplete: 'off',
                }}
                onKeyDown={handleKeyDown}
                handleChange={handlePhoneChange}
                validation={{
                  maxLength: {
                    value: 11,
                    message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                  },
                  minLength: {
                    value: 11,
                    message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                  },
                  pattern: {
                    value: phoneRegex,
                    message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                  },
                  validate: validateExperianPhone,
                }}
              />
            </Box>
            <Box pt={6}>
              <FormControlLabel
                className={classes.checkboxesContainer}
                label="I am a current Community Fibre customer"
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked)
                    }}
                  />
                }
              />
            </Box>
            {checked &&
            <>
              <legend>
                <Typography variant="body2">
                  Please enter your address below so we can help give you more specific advice
                </Typography>
              </legend>
              <Box pt={4}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Address line 1"
                  placeholder="Flat 24 Gisburn House"
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: addressRegex,
                      message: 'Please use a valid address. Insert alphabetic characters and numbers.',
                    },
                    minLength: {
                      value: 4,
                      message: 'Minimum length is 4 characters. Please use a valid address.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum length is 50 characters. Please use a valid address.',
                    },
                  }}
                />
              </Box>
              <Box pt={6}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Address line 2"
                  placeholder="Friary Estate"
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: addressRegex,
                      message: 'Please use a valid address. Insert alphabetic characters and numbers.',
                    },
                    minLength: {
                      value: 4,
                      message: 'Minimum length is 4 characters. Please use a valid address.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum length is 50 characters. Please use a valid address.',
                    },
                  }}
                />
              </Box>
              <Box pt={6}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Address line 3"
                  placeholder=""
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: addressRegex,
                      message: 'Please use a valid address. Insert alphabetic characters and numbers.',
                    },
                    minLength: {
                      value: 4,
                      message: 'Minimum length is 4 characters. Please use a valid address.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum length is 50 characters. Please use a valid address.',
                    },
                  }}
                />
              </Box>
              <Box pt={6}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Town or city"
                  placeholder="London"
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: /^(?!.*(?<char>[a-zA-Z\s-])\k<char>{2})(?!.*\s{2})[a-zA-Z\s-]+$/,
                      message: 'Please use a valid city name. Insert alphabetic characters only',
                    },
                    minLength: {
                      value: 3,
                      message: 'Minimum length is 3 characters. Please use a valid city name.',
                    },
                    maxLength: {
                      value: 25,
                      message: 'Maximum length is 25 characters. Please use a valid city name.',
                    },
                  }}
                />
              </Box>
              <Box pt={6}>
                <TextInput
                  className={classes.fieldClass}
                  gridWidth={12}
                  label="Postcode"
                  placeholder="SE15 1SE"
                  type="text"
                  handleChange={specialCharactersSanitizer}
                  validation={{
                    pattern: {
                      value: /^[a-zA-Z]{1,2}\d[a-zA-Z\d]? \d[a-zA-Z]{2}$/,
                      message: 'Please use a valid UK postcode. Insert alphabetic characters and numbers (I.e SE15 1SE)',
                    },
                    minLength: {
                      value: 4,
                      message: 'Minimum length is 4 characters. Please use a valid UK postcode.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum length is 50 characters. Please use a valid UK postcode.',
                    },
                  }}
                />
              </Box>
            </>}
            <Box pt={9}>
              <FormControl required className={cx(classes.selectInput, requiredError && classes.requiredError)}>
                <InputLabel htmlFor="reasonForContacting">
                  Reason For Contacting
                </InputLabel>
                <Typography variant="body2">
                  Please select your contact reason carefully as this will help
                  your request to be solved faster.
                </Typography>
                <Controller
                  id="reasonForContacting"
                  control={control}
                  rules={{ required: true }}
                  name="reasonForContacting"
                  defaultValue="Please select a reason"
                  renderValue={(selected: string) => {
                    if (!selected) {
                      return 'Please select a reason'
                    }

                    setSelectValue(selected)
                    return selectValue
                  }}
                  as={
                    <Select>
                      {contactReasons.map((reason) => (
                        <MenuItem key={reason} value={reason}>
                          {reason}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {requiredError && <Typography variant="body2">Reason is required</Typography>}
              </FormControl>
              {customBox &&
                <Box pt={2}>
                  <TextInput
                    className={classes.fieldClass}
                    gridWidth={12}
                    required
                    label="Please write your reason"
                    placeholder=""
                    type="text"
                  />
                </Box>}
            </Box>
            <Box pt={6}>
              <InputLabel htmlFor="enquiryDetails">
                Please fill in as much detail as possible about your enquiry*
              </InputLabel>
              <Controller
                required
                control={control}
                name="enquiryDetails"
                id="enquiryDetails"
                defaultValue=""
                as={<textarea/>}
              />
            </Box>
            <Box pt={4}>
              <StyledButton type="submit" color="primary" disabled={isSubmitting}>
                <Typography noWrap>Submit</Typography>
                {isSubmitting && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
              </StyledButton>
            </Box>
            {error &&
            <Box pt={4}>
              <Paper id="message-error" className={classes.error}>
                <Typography variant="body2">Something went wrong. Please refresh the page and try again later.</Typography>
              </Paper>
            </Box>}
          </Grid>
        </form>
      </Container>
    </FormContext>
  )
}
