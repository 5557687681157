import {
  css,
} from 'linaria'
import commonStyling from '../../../../../styles/commonStyling'
import fontSizes from '../../../../../styles/fontSizes'
import theme, { COLORS } from '../../../../../styles/theme'

export const classes = {
  hidenProducts: css`
    display:none;
  `,
  container: css`
    color: ${theme.palette.primary.main};
    .MuiSvgIcon-colorSecondary {
      cursor: pointer;
    }
    #payment-error {
      margin-top: ${theme.spacing(5)}px;
    }
  `,
  detailContainer: css`
    &.MuiGrid-root {
      background-color: ${theme.palette.warning.light};
      padding: ${theme.spacing(3)}px;
      border-radius: ${commonStyling.roundedBorders.all};
      margin: ${theme.spacing(1)}px 0;
      .MuiTypography-body2 {
        font-weight: ${theme.typography.fontWeightLight!};
      }
      ${theme.breakpoints.up('md')} {
          flex-basis: 49%;
          margin: ${theme.spacing(2)}px 0;
        }
      }
  `,
  infoBanner: css`
    display: flex;
    align-items: center;
    color: white;
    background-color: ${theme.palette.warning.main};
    border-radius: ${commonStyling.roundedBorders.all};
    padding: ${theme.spacing(2)}px;
    box-shadow: 0px 3px 6px #00000054;
    .MuiSvgIcon-root {
      height: 30px;
      width: 30px;
      margin-right: ${theme.spacing(1)}px;
    }
  `,
  infoBannerPrimary: css`
    background-color: ${theme.palette.primary.main};
  `,
  productsContainer: css`
    &.MuiGrid-container {
      margin: ${theme.spacing(1)}px 0;
      padding: ${theme.spacing(3)}px;
      background-color: ${theme.palette.warning.light};
      border-radius: ${commonStyling.roundedBorders.all};
    }
    .MuiGrid-grid-md-6:nth-child(2) {
      border-bottom: 1px solid ${COLORS.gray2};
    }
    .MuiGrid-grid-md-6:nth-child(2) {
      .MuiBox-root > div {
        display: flex;
        padding-top: ${theme.spacing(1)}px;
        .MuiSvgIcon-root {
          margin-right: 5px;
          position: relative;
          top: 5px;
        }
      }
      ${theme.breakpoints.up('md')} {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        .MuiBox-root > div {
          flex-direction: row-reverse;
          .MuiSvgIcon-root {
            margin-left: ${theme.spacing(1)}px;
          }
        }
      }
      ${theme.breakpoints.up('lg')} {
        .MuiBox-root > div {
          align-items: baseline;
        }
        .MuiSvgIcon-root {
          top: 2px;
        }
      }
    }
    .MuiTypography-body2 {
      font-weight: ${theme.typography.fontWeightLight!};
    }
    .MuiTypography-colorSecondary {
      color: ${theme.palette.secondary.contrastText};
      span:nth-child(2) {
        font-size: ${fontSizes.body1};
      }
    }
    ${theme.breakpoints.up('md')} {
      &.MuiGrid-container {
        margin: ${theme.spacing(2)}px 0;
      }
      .MuiGrid-grid-md-6:first-child {
        border-bottom: 1px solid ${COLORS.gray2};
        border-radius: 0% !important;
      }
      .MuiGrid-grid-md-6:last-child {
        text-align: right;
        padding-top: ${theme.spacing(3)}px !important;
      }
    }
  `,
  monthlyCost: css`
    color: ${theme.palette.secondary.contrastText};
  `,
  discount: css`
    color: ${theme.palette.secondary.contrastText};

    &.MuiTypography-root {
      font-size: 15px;
      font-weight: normal;
    }
  `,
  vulnerableMention: css`
    padding-top: ${theme.spacing(4)}px;
    
    .MuiTypography-root {
      font-size: ${theme.typography.body2.fontSize!}px;
      font-weight: normal;
    }
  `,
  wier: css`
    display: flex;
    align-items: flex-end;

    svg {
      font-size: 1.8rem;
      margin-right: 2px !important;
      ${theme.breakpoints.down('sm')} {
        left: -2px;
      }
    }
  `,
}
