import Axios, { AxiosInstance } from 'axios'
import uuid from 'uuid'
import env from '../utils/env'
import Hobs from './Hobs'
import HobsProducts from './Products'
import HobsPackages from './Packages'
import Addresses from './Addresses'
import Leads from './Leads'
import HobsLeads from './HobsLeads'
import BookingCalendar from './BookingCalendar'
import Experian from './Experian'
import Accounts from './Accounts'
import WorkOrder from './WorkOrder'
import Cart from './Cart'
import Payment from './Payment'
import SubmitOrder from './SubmitOrder'
import Pinpoint from './Pinpoint'
import ContactUs from './ContactUs'
import HobsReferral from './Referral'
import HobsOrderInfo from './OrderInfo'

export class CRMApiClient {
  public readonly payment: ReturnType<typeof Payment>
  public readonly addresses: ReturnType<typeof Addresses>
  public readonly leads: ReturnType<typeof Leads>
  public readonly hobsLeads: ReturnType<typeof HobsLeads>
  public readonly accounts: ReturnType<typeof Accounts>
  public readonly submitOrder: ReturnType<typeof SubmitOrder>
  public readonly bookingCalendar: ReturnType<typeof BookingCalendar>
  public readonly experian: ReturnType<typeof Experian>;
  public readonly workOrder: ReturnType<typeof WorkOrder>;
  public readonly cart: ReturnType<typeof Cart>;
  public readonly hobsPackages: ReturnType<typeof HobsPackages>;
  public readonly hobsProducts: ReturnType<typeof HobsProducts>;
  public readonly hobsReferral: ReturnType<typeof HobsReferral>;
  public readonly hobs: ReturnType<typeof Hobs>
  public readonly pinpoint: ReturnType<typeof Pinpoint>
  public readonly contactUs: ReturnType<typeof ContactUs>
  public readonly hobsOrderInfo: ReturnType<typeof HobsOrderInfo>

  constructor() {
    const sessionId = uuid.v4()
    const axiosInstance = Axios.create({
      baseURL: env.CRMApiUrl,
      responseType: 'json',
      headers: {
        'access-token': env.CRMAccessToken,
        'client-secret': env.CRMClientSecret,
        'request-source': env.requestSource,
        'user-id': '5be4c5577247450016ba8d7c',
      },
    })
    const axiosInstanceZinier = Axios.create({
      baseURL: `${env.ZinierApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const axiosInstanceExperian = Axios.create({
      baseURL: `${env.ExperianApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const axiosInstanceAddressSearch = Axios.create({
      baseURL: `${env.AddressSearchUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const axiosInstancePhonixSearch = Axios.create({
      baseURL: `${env.PhoenixApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const axiosInstancePremiseCoverageUrl = Axios.create({
      baseURL: `${env.PremiseCoverageApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const axiosInstanceBusinnessAdressSearch = Axios.create({
      baseURL: `${env.BusinessAddressSearch}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': env.CRMAccessToken,
        'client-secret': env.CRMClientSecret,
        'user-id': '62fb503097876d01f5d166c2',
      },
    })

    const axiosInstanceHobs = Axios.create({
      baseURL: `${env.HobsApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        userID: 'CFLHOBS',
      },
    })
    const axiosInstanceHobsReferral = Axios.create({
      baseURL: `${env.HobsApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        userID: 'CFLHOBS',
        'If-None-Match': 'empty',
      },
    })
    const axiosInstanceCreateHobsUser = Axios.create({
      baseURL: `${env.HobsApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const axiosInstanceRegisterInterest = Axios.create({
      baseURL: `${env.HobsApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        apikey: 'vewefew',
        opId: 'CFL',
        buId: 'DEFAULT',
        userId: 'CFLHOBS',
        lang: 'ENG',
      },
    })
    const axiosInstancePinpoint = Axios.create({
      baseURL: `${env.PinpointApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const axiosCartInstanceHobs = Axios.create({
      baseURL: `${env.HobsApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        userID: 'CFLHOBS',
        opID: 'CFL',
        buID: 'DEFAULT',
      },
    })

    const axiosPaymentInstanceHobs = Axios.create({
      baseURL: `${env.PaymentApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        apikey: env.HobsApiKey,
      },
    })

    const getBearerToken = (instance: AxiosInstance) => {
      instance.interceptors.request.use(
        async (config) => {
          const token =
          sessionStorage && sessionStorage.getItem('access_token') ?
            'Bearer ' + sessionStorage.getItem('access_token') :
            null
          if (token) {
            config.headers.authorization = token
          }

          return config
        },
        (error) => Promise.reject(error),
      )
    }

    const instances = [
      axiosInstanceZinier,
      axiosInstanceExperian,
      axiosInstanceAddressSearch,
      axiosInstanceHobs,
      axiosInstanceHobsReferral,
      axiosCartInstanceHobs,
      axiosPaymentInstanceHobs,
      axiosInstanceCreateHobsUser,
      axiosInstancePinpoint,
      axiosInstancePremiseCoverageUrl,
      axiosInstanceRegisterInterest,
    ]

    instances.forEach(getBearerToken)

    this.payment = Payment(axiosPaymentInstanceHobs)
    this.addresses = Addresses(
      {
        addressSearchUrl: axiosInstanceAddressSearch,
        premiseCoverageUrl: axiosInstancePremiseCoverageUrl,
        phoenixAddressSearch: axiosInstancePhonixSearch,
        businessAdrressSearch: axiosInstanceBusinnessAdressSearch,
      },
      sessionId,
    )
    this.leads = Leads(axiosInstance)
    this.hobsLeads = HobsLeads(
      {
        axiosInstanceCreateHobsUser,
        axiosInstanceRegisterInterest,
      },
    )
    this.hobsReferral = HobsReferral(axiosInstanceHobsReferral)
    this.bookingCalendar = BookingCalendar(axiosInstanceZinier)
    this.experian = Experian(axiosInstanceExperian)
    this.accounts = Accounts(axiosInstance)
    this.workOrder = WorkOrder(axiosInstanceZinier)
    this.cart = Cart(axiosCartInstanceHobs)
    this.hobsPackages = HobsPackages(axiosInstanceHobs)
    this.hobsProducts = HobsProducts(axiosInstanceHobs)
    this.hobs = Hobs(axiosInstanceHobs)
    this.submitOrder = SubmitOrder(axiosInstanceHobs)
    this.pinpoint = Pinpoint(axiosInstancePinpoint)
    this.contactUs = ContactUs(axiosInstanceHobs)
    this.hobsOrderInfo = HobsOrderInfo(axiosInstanceHobs)
  }
}

export default function createCRMApiClient(): CRMApiClient {
  return new CRMApiClient()
}
