/* eslint-disable prefer-named-capture-group */
import React, {
  ChangeEvent, useState, useEffect,
} from 'react'
import { cx } from 'linaria'
import {
  TextField,
  Fab,
  Typography,
  Grid,
  Popper,
  PopperProps,
  useMediaQuery,
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import { useForm } from 'react-hook-form'
import usePremiseSearch from '../../utils/hooks/usePremiseSearch'
import {
  SearchPremise, AcceptingType, PremiseDetail,
} from '../../api/Addresses'
import classes from './SearchBar.styles'
import marketingEvents from '../../utils/marketing/marketingEvents'
import isAddressQueryLongEnough from '../../utils/isAddressQueryLongEnough'
import createCRMApiClient from '../../api/CRMApi'
import {
  premiseStore,
} from '../../storage'
import LoadingSpinner from './LoadingSpinner'
import { useGeneralContext } from '../GeneralContext/GeneralContext'
import theme from '../../styles/theme'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import { DCMSProps } from '../Packages/Helpers'
import {
  PlatformType, SegmentType,
} from '../../utils/commonEnums'

export interface SearchBarProps {
  readonly title?: string;
  readonly placeholder_text: string;
  readonly placeholder_mobile_text: string;
  readonly search_button_text: string;
  readonly loading_text: string;
  readonly no_address_found_text: string;
  readonly error_text: string;
  readonly is_primary?: boolean;
  id?: string;
  goToBundles?: boolean;
  goToCheckout?: boolean;
  goToHomeschool?: boolean;
  hide_submit_button?: boolean;
  className?: string;
  redirect_url?: string;
  redirect_to_affiliate?: boolean;
  border_colour?: string;
  is_business_search?: boolean;
  dcms_challenge_area?: string;
  redirect_to?: string;
}

export default function SearchBar({
  title,
  placeholder_text,
  placeholder_mobile_text,
  search_button_text,
  loading_text,
  no_address_found_text,
  error_text,
  is_primary,
  goToBundles,
  goToCheckout,
  goToHomeschool,
  hide_submit_button,
  className,
  redirect_url,
  redirect_to_affiliate,
  id,
  border_colour,
  is_business_search,
  dcms_challenge_area,
  redirect_to,
}: SearchBarProps) {
  const client = createCRMApiClient()
  const {
    query,
    setQuery,
    premises,
    getPremise,
    isFetchingQuery,
  } = usePremiseSearch()

  const {
    generalOptions, setGeneralOptions,
  } = useGeneralContext()

  const {
    options, setOptions,
  } = useOrderContext()

  const {
    cartInstanceIdentifier, channel, broadband, bundle,
  } = options

  const {
    urlPath,
  } = generalOptions

  // 7 days
  const ttl = 7 * 86400
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const outOfLondonPageSlug = '/not-in-london'
  const bundlesQuery = goToBundles || redirect_to === 'broadband-bundles' ? '?bundles=true' : ''
  const extrasQuery = (redirect_to === 'extras' && (Boolean(broadband) || Boolean(bundle))) ? '?extras=true' : ''
  const checkoutQuery = goToCheckout || (redirect_to === 'checkout' && (Boolean(broadband) || Boolean(bundle))) ? '?checkout=true' : ''
  const homeSchoolQuery = goToHomeschool ? '?homeschool=true' : ''

  const {
    register, handleSubmit, setValue, errors,
  } = useForm<{
    address: string;
    sprn: string;
  }>()

  const [
    showSpinner,
    setShowSpinner,
  ] = useState<boolean>(false)

  const [
    businessQuery,
    setBusinessQuery,
  ] = useState<string>('')

  const [
    error,
    setError,
  ] = useState<string | null>(null)

  const checkSegment = is_business_search ? SegmentType.BUSINESS : SegmentType.RESIDENTIAL
  const apiErrorMsg = 'We’re sorry, but something went wrong on our end, please try again later.'

  useEffect(() => {
    const resetSpinner = (e: PageTransitionEvent) => {
      if (e.persisted) {
        setShowSpinner(false)
      }
    }

    window.addEventListener('pageshow', (e) => resetSpinner(e))

    return () => window.removeEventListener('pageshow', (e) => resetSpinner(e))
  }, [])

  function handleListItemClick(
    _event: ChangeEvent<{}>,
    premise: SearchPremise | null,
  ) {
    if (premise && premise.address && premise.sprn) {
      setShowSpinner(true)
      setValue('address', premise.address)
      setValue('sprn', String(premise.sprn))
      marketingEvents.selectedAddress(premise as PremiseDetail, checkSegment, options)

      searchSubmit()
    } else {
      setValue('address', '')
      setValue('sprn', '')
    }
  }

  const scrollSearchbarIntoView = () => {
    marketingEvents.simpleEvent('address_interaction', options)

    if (!window) {
      return
    }

    const documentWidth: number = window.innerWidth
    if (documentWidth < 700) {
      const searchBar = document.querySelector('#searchbarWrapper')
      const scrollToSearchBar = () => {
        searchBar && searchBar.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }

      setTimeout(scrollToSearchBar, 250)
    }
  }

  const DropdownPopper = (props: PopperProps) => {
    return (
      <Popper
        {...props}
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
        data-test-id="autoCompleteMenu"
      />
    )
  }

  const isBusinessPremise = (premise: PremiseDetail) => {
    return premise.coverage_indicator === 'C' ? premise.class_type !== 'Residential' : premise.box?.property_type === 'B2B'
  }

  const redirectToUrl = (url: string) => {
    location.assign(url)
  }

  const savePremise = async (premise: PremiseDetail, ttl: number) => {
    await premiseStore.set(premise, ttl)
  }

  /* eslint complexity: ["error", 100] */
  const residentialSearchSubmit = handleSubmit(async ({ sprn }) => {
    const premise = await createCRMApiClient().addresses.get(Number(sprn))
    if (!premise || 'error' in premise) {
      setError(apiErrorMsg)
      return
    }

    // DCMS logic -- start
    const areas = dcms_challenge_area?.toLowerCase().replace(/\s/g, '').split('|')
    const dcmsChallengeArea = Boolean(
    premise?.box && premise.box.dcms_grant_flag === 'Y' && premise.box.dcms_eligibility === 'I' && areas?.includes(premise.box.project_name.toLowerCase()))
    const dcmsEligibility = Boolean(
    premise?.box && ((premise.box.dcms_grant_flag === 'Y' && premise.box.dcms_eligibility === 'E') || dcmsChallengeArea))

    setOptions({
      ...options,
      dcmsEligibility,
      platform: premise?.box ? PlatformType.BOX : PlatformType.CF,
    })

    if (!dcmsEligibility && cartInstanceIdentifier && channel) {
      await client.cart.removeProductFromCart(cartInstanceIdentifier!, 'Dcms_Product', channel)
    }

    if (dcmsEligibility && cartInstanceIdentifier && channel && broadband) {
      const DCMSObject: DCMSProps = {
        cartInstanceIdentifier,
        channel,
        product: broadband,
      }

      await client.cart.addDCMSToCart(DCMSObject)
    }
    // DCMS logic -- end

    marketingEvents.addressSubmit(premise, checkSegment, options)
    marketingEvents.onFootprint(premise, checkSegment, options)

    if (!premise.is_in_london) {
      return redirectToUrl(outOfLondonPageSlug)
    }

    // Box Live customers logic -- start
    if (premise.box && premise.box.status === '80' && premise.box.sales_status === '1') {
      marketingEvents.simpleEvent('box_live_page_visit', options)
      return redirectToUrl('box-active-customer')
    }
    // Box Live customers logic -- end

    const registerInterest =
    [
      AcceptingType.none,
      AcceptingType.register_interest,
      AcceptingType.register_interest_early,
    ].includes(premise.accepting)
    const shouldRedirectToAffiliate = redirect_to_affiliate && urlPath && premise.bd_status
    const shouldRedirectToCustomUrl = redirect_url && !registerInterest

    if (isBusinessPremise(premise)) {
      return redirectToUrl(premise.coverage_indicator === 'C' ? 'business-address' : 'business-address-form')
    }

    if (shouldRedirectToAffiliate) {
      setGeneralOptions({
        ...generalOptions,
        sprn: parseInt(sprn, 10),
      })
      savePremise(premise, ttl)
      return redirectToUrl(`${urlPath}?sprn=${sprn}`)
    }

    if (shouldRedirectToCustomUrl) {
      savePremise(premise, ttl)
      marketingEvents.checkedAddress(premise)
      return redirectToUrl(`${redirect_url}?sprn=${sprn}`)
    }

    savePremise(premise, ttl)
    marketingEvents.checkedAddress(premise)

    const shortPremise = getPremise(Number(sprn))
    if (shortPremise) {
      return redirectToUrl(`premise-result/${sprn}${bundlesQuery}${extrasQuery}${checkoutQuery}${homeSchoolQuery}`)
    }
  })

  const businessSearchSubmit = handleSubmit(async ({ sprn }) => {
    const apiClient = createCRMApiClient()
    const premise = await apiClient.addresses.get(Number(sprn))
    if (!premise || 'error' in premise || !premise.is_in_london) {
      if (!premise || 'error' in premise) {
        setError(apiErrorMsg)
      } else {
        redirectToUrl(outOfLondonPageSlug)
      }

      return
    }

    const isBusiness = premise.class_type === 'Business'
    const hasStatus803 = premise.status === 80 && premise.sales_status === 3

    if (isBusiness) {
      const businessPremise = await apiClient.addresses.getBusiness(Number(sprn))
      if (!businessPremise || 'error' in businessPremise) {
        setError(apiErrorMsg)
        return
      }

      const distance = businessPremise.closestliveaddress_metrestocab!
      if (hasStatus803) {
        marketingEvents.simpleEvent('b2b_second-fix_page_visit', options)
        return redirectToUrl('business/second-fix')
      }

      if (typeof distance === 'number') {
        distanceLogic(distance)
      } else {
        marketingEvents.simpleEvent('b2b_nocoverage_page_visit', options)
        redirectToUrl('business/nocoverage')
      }

      savePremise(premise, ttl)
    } else if (hasStatus803) {
      marketingEvents.simpleEvent('b2b_second-fix_page_visit', options)
      redirectToUrl('business/second-fix')
    } else {
      redirectToUrl('business/residential-address')
    }
  })

  function searchSubmit() {
    if (is_business_search) {
      businessSearchSubmit()
    } else {
      residentialSearchSubmit()
    }
  }

  function distanceLogic(distance: number) {
    const distanceInNumber = Math.round((distance))
    if (distanceInNumber === 0 || distanceInNumber === 1) {
      marketingEvents.simpleEvent('b2b_second-fix_page_visit', options)
      return redirectToUrl('business/second-fix')
    }

    if (distanceInNumber < 200) {
      marketingEvents.simpleEvent('b2b_success_page_visit', options)
      return redirectToUrl('business/success')
    }

    if (distanceInNumber >= 200 && distanceInNumber <= 400) {
      marketingEvents.simpleEvent('b2b_verification_page_visit', options)
      return redirectToUrl('business/verification')
    }

    if (distanceInNumber > 400) {
      marketingEvents.simpleEvent('b2b_nocoverage_page_visit', options)
      return redirectToUrl('business/nocoverage')
    }
  }

  const businessByPostcode = async (postcode: string) => {
    const businessPostcode = await createCRMApiClient().addresses.getBusinessByPostcode(postcode)
    if (!businessPostcode || 'error' in businessPostcode) {
      setError(apiErrorMsg)
      return
    }

    const closestLocation = businessPostcode.closestliveaddress_metrestocab
    if (!businessPostcode.postcode) {
      setError(error_text)
      marketingEvents.simpleEvent('b2b_postcode_error', options)
      return
    }

    if (businessPostcode && !businessPostcode.is_in_london) {
      return redirectToUrl(outOfLondonPageSlug)
    }

    if (businessPostcode.postcode && closestLocation !== null && closestLocation !== undefined) {
      distanceLogic(closestLocation)
    } else {
      marketingEvents.simpleEvent('b2b_nocoverage_page_visit', options)
      redirectToUrl('business/nocoverage')
    }
  }

  function getOptionsText() {
    return isAddressQueryLongEnough(query) && !isFetchingQuery ?
      no_address_found_text :
      'Start typing to find your address'
  }

  function getPlaceholderText({ isMobile }: { isMobile: boolean }) {
    if (errors.sprn) {
      return error_text
    }

    return isMobile ? placeholder_mobile_text : placeholder_text
  }

  const borderColor = () => {
    if (!error) {
      return border_colour ? `1px solid ${border_colour}` : 'none'
    }

    return `1px solid ${theme.palette.error.main}`
  }

  const showArrowButton = !hide_submit_button
  const formClass = is_primary ?
    cx(classes.form, classes.formPrimaryBg) :
    classes.form
  const containerClass = cx(is_primary && classes.fontContainerPrimary)
  const popperClass = cx(classes.popper, is_primary && classes.popperPrimary)

  const renderSearchbar = () => {
    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justify="space-between"
        classes={{ root: containerClass }}
      >
        <Grid item classes={{ root: classes.inputContainer }}>
          <input hidden name="sprn" ref={register({ required: true })}/>
          <Autocomplete
            id="addressesList"
            options={premises}
            noOptionsText={getOptionsText()}
            loading={isFetchingQuery || showSpinner}
            loadingText={loading_text}
            filterOptions={options => options}
            popupIcon={null}
            getOptionLabel={(option) => option.address ?? option}
            PopperComponent={DropdownPopper}
            disablePortal
            debug
            disableClearable
            disabled={showSpinner}
            classes={{
              root: classes.autocomplete,
              popper: popperClass,
              listbox: classes.item,
            }}
            style={border_colour ? {
              border: `1px solid ${border_colour}`,
            } : { border: 'none' }}
            onFocus={scrollSearchbarIntoView}
            onChange={handleListItemClick}
            renderInput={params => (
              <Grid container direction="row" justify="space-between">
                <Box
                  data-cy="search-bar-text-field"
                  display="flex"
                  alignItems="center"
                  className={classes.searchbar}
                >
                  <TextField
                    placeholder={isMobile ? getPlaceholderText({ isMobile: true }) : getPlaceholderText({ isMobile: false })}
                    onChange={e => setQuery(e.target.value)}
                    {...params}
                    type="text"
                    name="address"
                    variant="outlined"
                    onKeyDown={(event) => event.key === 'Enter' && event.preventDefault}
                    inputRef={register({ required: true })}
                    classes={errors.sprn ? { root: classes.errorMessage } : {}}
                  />
                </Box>
                {showArrowButton && !showSpinner && !isFetchingQuery && (
                  <Fab aria-label={search_button_text} className={classes.mobileButton}>
                    <SearchIcon/>
                  </Fab>
                )}
                {(isFetchingQuery || showSpinner) && (
                  <LoadingSpinner/>
                )}
              </Grid>
            )}
          />
        </Grid>
      </Grid>
    )
  }

  const renderBusinessSearchbarByPostcode = (expanded?: boolean) => {
    return (
      <Grid item container id="second_searchbarWrapper">
        <ExpansionPanel expanded={expanded}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMore/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body2" color="secondary">Can&apos;t find your address?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justify="space-between"
              classes={{ root: containerClass }}
            >
              <Grid item container direction="column">
                <Grid
                  container direction="row" justify="space-between"
                  className={classes.autocomplete}
                  style={{ border: borderColor() }}
                >
                  <TextField
                    placeholder="Enter postcode"
                    onChange={(e) => {
                      const postcode = e.target.value.toUpperCase()
                        .toString()
                        .replace(/\s/g, '')
                        .replace(/^(.*)(\d)/, '$1 $2')
                      setBusinessQuery(postcode)
                      setError(null)
                      marketingEvents.simpleEvent('b2b_postcode_interaction', options)
                    }}
                    type="text"
                    name="address"
                    variant="outlined"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        businessByPostcode(businessQuery)
                      }
                    }}
                    inputRef={register({ required: true })}
                  />
                  {showArrowButton && !showSpinner && !isFetchingQuery && (
                    <Fab aria-label={search_button_text} className={classes.mobileButton}>
                      <SearchIcon onClick={() => businessByPostcode(businessQuery)}/>
                    </Fab>
                  )}
                </Grid>
                {error && <Typography variant="caption" className={classes.errorMessage}>{error}</Typography>}
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    )
  }

  return (
    <form className={cx(formClass, className)} onSubmit={searchSubmit} id={id}>
      {title && <Typography variant="h3">{title}</Typography>}
      {renderSearchbar()}
      {is_business_search && renderBusinessSearchbarByPostcode()}
    </form>
  )
}
