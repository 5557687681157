import React, { useState } from 'react'
import { css } from 'linaria'
import {
  Button, Container, Grid, Typography,
} from '@material-ui/core'
import chunk from 'lodash.chunk'
import LayoutItems from '../LayoutItems/LayoutItems'
import {
  BlogPost, PostListResponse,
} from '../../../utils/blog'
import theme from '../../../styles/theme'
import butter from '../../../butter'

export interface ItemOverviewProps {
  readonly item_data: PostListResponse;
  readonly button_text: string;
  type_items: string;
  page_size_items: number;
}

const classes = {
  container: css`
    padding: ${theme.spacing(6, 0)};
    .MuiGrid-item {
      padding: ${theme.spacing(2)}px;
      display: flex;
      justify-content: center;
    }
  `,
  moreButton: css`
    text-align: center;
    .MuiButtonBase-root {
      height: 42px;
      margin: 0 auto;
    }
  `,
}

export default function ItemOverview({
  item_data,
  button_text,
  type_items,
  page_size_items,
}: ItemOverviewProps) {
  const [
    postData,
    setPostData,
  ] = useState(item_data)

  const {
    data, meta,
  } = postData

  if (data.length === 0) {
    return null
  }

  async function onFetchMore() {
    if (!meta.next_page) {
      return
    }

    const nextPage = await butter.post.list({
      page: meta.next_page,
      page_size: page_size_items,
      exclude_body: true,
      category_slug: type_items,
    })

    setPostData({
      meta: nextPage.data.meta,
      data: postData.data.concat(nextPage.data.data),
    })
  }

  const postChunks = chunk(data, page_size_items)

  return (
    <Container classes={{ root: classes.container }}>
      <Grid container direction="column">
        {postChunks.map((chunk: BlogPost[], i: number) => (
          <Grid key={`${type_items}_page_${i}`} container>
            <LayoutItems post_items={chunk} type_items={type_items}/>
          </Grid>
        ))}
        {postData.meta.next_page && (
          <Grid className={classes.moreButton} item xs>
            <Button color="primary" disableElevation onClick={onFetchMore} variant="contained">
              <Typography noWrap>{button_text}</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
