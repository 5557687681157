/* eslint complexity: ["error", 100] */
import React, {
  useEffect,
  useState,
} from 'react'
import {
  Grid, FormControlLabel, Link,
  FormLabel, Typography, Checkbox,
} from '@material-ui/core'
import {
  Controller, useFormContext,
} from 'react-hook-form'
import TextInput from '../TextInput/TextInput'
import useSavedPremise from '../../utils/hooks/useSavedPremise'
import { arraysEqual } from '../../utils/arraysEqual'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { JsonObject } from 'type-fest'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import { experianStore } from '../../sessionStorage'
import { ExperianOptions } from '../ExperianValidation/ExperianValidation'
import createCRMApiClient from '../../api/CRMApi'
import {
  ExperianEmail,
  ExperianPhone,
} from '../../api/Experian'
import {
  OrderOptions, useOrderContext,
} from '../NewOrderFlow/OrderContext'
import { general } from '../../styles/theme'
import SimpleBox from '../SimpleBox/SimpleBox'
import classes from './PersonalDetails.styles'
import {
  specialCharactersSanitizer, handleKeyDown, handlePhoneChange, nameRegex, phoneRegex, emailRegex,
} from '../../utils'

interface GridItemConfig {
  xs?: boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 'auto' | 11 | 12 | undefined;
  md?: boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 'auto' | 11 | 12 | undefined;
}

export interface GridConfig {
  firstName?: GridItemConfig;
  lastName?: GridItemConfig;
  address?: GridItemConfig;
  email?: GridItemConfig;
  phone?: GridItemConfig;
  confirmEmail?: GridItemConfig;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
}

export default function PersonalDetails({
  showEmailField = true,
  showConfirmEmailField = true,
  showLandlineField = true,
  showConsent = true,
  showConsentText = true,
  formFieldsClass = '',
  gridClassName = '',
  children,
  formData,
  consentText,
  savedDataClassName,
  consentInfoClassName,
  addressFieldClassName,
  gridConfig,
  campaignId,
  hideAddress,
}: {
  showEmailField?: boolean;
  showConfirmEmailField?: boolean;
  showLandlineField?: boolean;
  showConsent?: boolean;
  showConsentText?: boolean;
  children?: React.ReactNode;
  formData?: JsonObject;
  consentText?: string;
  savedDataClassName?: string;
  consentInfoClassName?: string;
  addressFieldClassName?: string;
  gridConfig?: GridConfig;
  formFieldsClass?: string;
  gridClassName?: string;
  campaignId?: string;
  hideAddress?: boolean;
}) {
  const {
    register, setValue, getValues, triggerValidation, formState,
    control,
  } = useFormContext()

  const {
    options, setOptions,
  } = useOrderContext()

  const [
    experianData,
    setExperianData,
  ] = useState<ExperianOptions | null>()

  useEffect(() => {
    (async () => {
      const response = await experianStore.get()
      if (response) {
        setExperianData(response)
      }

      setOptions({
        ...options,
        isDetailsStep: true,
      })
    })()
  }, [])

  useSavedPremise((premise) => {
    setValue('sprn', premise.sprn)
    setValue('address', premise.address)
  })

  const triggerEmailVerification = () => {
    const { confirmEmail } = getValues()
    if (confirmEmail) {
      triggerValidation('confirmEmail')
    }
  }

  const validateExperianEmail = async (input: string) => {
    const client = createCRMApiClient()

    if (!experianData?.email_validation) {
      return
    }

    const validationResponse: ExperianEmail = await (async () => {
      try {
        return await client.experian.validateEmail(input)
      } catch (err) {
        marketingEvents.error(options, 'ERR-EXPERIAN-1', 'Experian email validation failed', err.message)
        return err.message
      }
    })()

    for (const el of experianData?.email_validation_cases) {
      if (validationResponse?.result?.confidence === el.confidence) {
        marketingEvents.simpleEvent('checkout_email_validation_successful', options)
        return
      }
    }

    marketingEvents.simpleEvent('checkout_email_validation_fails', options)
    return experianData?.email_error
  }

  const verifyEmailFields = (input: string) => {
    const { email } = getValues()
    return input === email
  }

  const validateExperianPhone = async (input: string) => {
    const client = createCRMApiClient()

    if (!experianData?.phone_validation) {
      return
    }

    const validationResponse: ExperianPhone = await (async () => {
      try {
        return await client.experian.validatePhone(input)
      } catch (err) {
        marketingEvents.error(options, 'ERR-EXPERIAN-2', 'Experian phone validation failed', err.message)
        return err.message
      }
    })()

    for (const el of experianData?.phone_validation_cases) {
      if (validationResponse?.result?.confidence === el.confidence) {
        marketingEvents.simpleEvent('checkout_phone_validation_successful', options)
        return
      }
    }

    marketingEvents.simpleEvent('checkout_phone_validation_fails', options)
    return experianData?.phone_error
  }

  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      Object.keys(formData)
        .forEach(field => {
          setValue(field, formData[String(field)])
        })
    }
  }, [
    formData,
    setValue,
  ])

  useEffect(() => {
    const requiredFields: string[] = [
      'sprn',
      'address',
      'firstName',
      'lastName',
      'email',
      'confirmEmail',
      'phone',
      'contactMarketing',
    ]
    const completedFields: string[] = [...formState.dirtyFields]
    arraysEqual(completedFields, requiredFields) && marketingEvents.registerInterestFormCompleted()
  }, [getValues()])

  useEffect(() => {
    if (campaignId) {
      setValue('campaignId', campaignId)
    }
  }, [campaignId])

  const [
    checked,
    setChecked,
  ] = React.useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    setValue('contactMarketing', event.target.checked)
  }

  const handleInputBlur = (eventName: string, options: OrderOptions, validator: (value: string) => boolean) => (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value
    let isValid = false

    if (value && value.trim().length > 0) {
      if (eventName === 'checkout_details_email_completed') {
        isValid = emailRegex.test(value)
      } else if (eventName === 'checkout_details_phone_completed') {
        isValid = value.length === 11 && phoneRegex.test(value)
      } else {
        isValid = validator(value)
      }
    }

    if (isValid) {
      // Field is valid and complete, trigger GA event
      marketingEvents.simpleEvent(eventName, options)
    }
  }

  useEffect(() => {
    if (formData?.contactMarketing && typeof formData?.contactMarketing === 'boolean') {
      setChecked(formData?.contactMarketing)
    }
  }, [formData?.contactMarketing])

  return (
    <>
      <Grid container spacing={gridConfig?.spacing ?? 2} className={gridClassName}>
        <input hidden name="sprn" ref={register({ required: true })}/>
        {campaignId && <input hidden name="campaignId" ref={register()}/>}
        {!hideAddress &&
        <Grid item xs={gridConfig?.address?.xs ?? 12}>
          <TextInput
            className={savedDataClassName ?? classes.disabledField}
            disabled
            multiline
            label="Address"
            gridWidth={12}
            containerClassName={addressFieldClassName}
          />
        </Grid>}
        <Grid item xs={gridConfig?.firstName?.xs ?? 12} md={gridConfig?.firstName?.md ?? 6}>
          <TextInput
            className={formFieldsClass}
            gridWidth={12}
            required
            label="First Name"
            handleChange={specialCharactersSanitizer}
            inputProps={{
              onBlur: handleInputBlur('checkout_details_first_name_completed', options, (value) => nameRegex.test(value)),
            }}
            validation={{
              pattern: {
                value: nameRegex,
                message: 'Please use a valid name. Insert alphabetic characters only',
              },
              minLength: {
                value: 1,
                message: 'Minimum length is 3 characters',
              },
              maxLength: {
                value: 25,
                message: 'Maximum length is 25 characters',
              },
              validate: (value) => value.trim().length > 0 || 'First Name is required and cannot be just spaces',
            }}
          />
        </Grid>
        <Grid item xs={gridConfig?.lastName?.xs ?? 12} md={gridConfig?.lastName?.md ?? 6}>
          <TextInput
            className={formFieldsClass}
            gridWidth={12}
            required
            label="Last Name"
            handleChange={specialCharactersSanitizer}
            inputProps={{
              onBlur: handleInputBlur('checkout_details_last_name_completed', options, (value) => nameRegex.test(value)),
            }}
            validation={{
              pattern: {
                value: nameRegex,
                message: 'Please use a valid name. Insert alphabetic characters only',
              },
              minLength: {
                value: 1,
                message: 'Minimum length is 3 characters',
              },
              maxLength: {
                value: 25,
                message: 'Maximum length is 25 characters',
              },
              validate: (value) => value.trim().length > 0 || 'Last Name is required and cannot be just spaces',
            }}
          />
        </Grid>
        {showEmailField &&
        <Grid item xs={gridConfig?.email?.xs ?? 12} md={gridConfig?.email?.md ?? 6}>
          <TextInput
            className={formFieldsClass}
            gridWidth={12}
            required
            label="Email"
            placeholder="me@example.co.uk"
            type="email"
            inputProps={{
              onBlur: handleInputBlur('checkout_details_email_completed', options, (value) => emailRegex.test(value)),
            }}
            handleChange={triggerEmailVerification}
            validation={{ validate: validateExperianEmail }}
          />
        </Grid>}
        {showConfirmEmailField &&
        <Grid item xs={gridConfig?.confirmEmail?.xs ?? 12} md={gridConfig?.confirmEmail?.md ?? 6}>
          <TextInput
            className={formFieldsClass}
            gridWidth={12}
            required
            label="Confirm Email"
            placeholder="me@example.co.uk"
            id="confirmEmail"
            type="email"
            validation={{ validate: verifyEmailFields }}
          />
        </Grid>}
        <Grid item xs={gridConfig?.phone?.xs ?? 12} md={gridConfig?.phone?.md ?? 6}>
          <TextInput
            className={formFieldsClass}
            gridWidth={12}
            required
            label="Mobile Number"
            id="phone"
            type="tel"
            inputProps={{
              maxLength: 11,
              autoComplete: 'off',
              onBlur: handleInputBlur('checkout_details_phone_completed', options, (value) => value.length === 11 && phoneRegex.test(value)),
            }}
            onKeyDown={handleKeyDown}
            handleChange={handlePhoneChange}
            validation={{
              maxLength: {
                value: 11,
                message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
              },
              minLength: {
                value: 11,
                message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
              },
              pattern: {
                value: phoneRegex,
                message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
              },
              validate: validateExperianPhone,
            }}
          />
        </Grid>
      </Grid>
      {showLandlineField && <TextInput label="Landline Number" placeholder="Your Landline Number" type="tel"/>}
      {children}
      {showConsent &&
      <Grid item={showConsent || showConsentText} xs={12} className={consentInfoClassName}>
        <SimpleBox>
          <FormLabel component="legend">
            <Typography id="marketingLabel" variant="h5" className={general.font.weight.semibold} gutterBottom>Don’t miss out on exclusive offers!
            </Typography>
          </FormLabel>
          <Controller
            control={control}
            defaultValue={false}
            name="contactMarketing"
            color="primary"
            as={
              <FormControlLabel
                className={general.color.primary}
                aria-labelledby="marketingLabel"
                label="Sign me up for exclusive offers and news"
                color="secondary"
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={handleChange}
                  />
                }
              />
            }
          />
          {showConsentText && (consentText ?
            <Wysiwyg body={consentText} variant="caption"/> :
            <Typography component="div" variant="caption" color="primary">Opt-out anytime. By continuing I give permission to Community Fibre to process my order data and communicate to complete the transaction.
            </Typography>)}
        </SimpleBox>
      </Grid>}
      {showConsentText && consentText ?
        <Wysiwyg body={consentText} variant="caption"/> :
        <Grid item xs={12}>
          <Typography variant="body1" color="primary">You accept our&nbsp;
            <Link underline="always" href="/legal-stuff#consumer" target="_blank" rel="noopener noreferrer">
              T&Cs
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" href="/legal-stuff#privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link> by clicking below
          </Typography>
        </Grid>}
    </>
  )
}
