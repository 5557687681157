import React, { useContext } from 'react'
import {
  FormContext, useForm,
} from 'react-hook-form'
import {
  Button, Typography, CircularProgress,
} from '@material-ui/core'
import { css } from 'linaria'
import { useRouter } from 'next/router'

import createCRMApiClient from '../../../../../api/CRMApi'
import { useOrderContext } from '../../../OrderContext'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'
import logger from '../../../../../utils/logger'
import { utmStore } from '../../../../../storage'
import { getBaseStandartPrice } from '../../../../../utils/getProductDetails'
import theme from '../../../../../styles/theme'
import getStepUrl from '../../../getStepUrl'
import { useGeneralContext } from '../../../../GeneralContext/GeneralContext'
import { ChannelType } from '../../../../../utils/commonEnums'
import { PremiseContext } from '../../../../PremiseTracker/PremiseContext'

//
// const AWIN_COOKIE_NAME = '_aw_m_19595'

interface OrderButtonProps {
  readonly id?: string;
}

const classes = {
  orderButton: css`
    min-height: 46px;
    ${theme.breakpoints.up('xs')} {
      width: 325px;
    }
  `,
  loading: css`
  margin-left:${theme.spacing(3)}px;
  vertical-align: middle;
  `,
}

export default function OrderButton({ id }: OrderButtonProps) {
  const {
    options, setOptions,
  } = useOrderContext()

  const {
    generalOptions,
  } = useGeneralContext()

  const router = useRouter()

  const { premise } = useContext(PremiseContext)

  const {
    broadband,
    bundle,
    data,
    paymentDetails,
    timeslot,
    error_5000_url,
    error_5001_url,
    default_error_url,
    cartInstanceIdentifier,
    channel,
    cks,
    reservationId,
    voice,
    alternativePhone,
  } = options

  const {
    firstName, lastName, email,
    phone, readTerms,
  } = data

  const methods = useForm()

  const {
    handleSubmit, formState,
  } = methods

  const { isSubmitting } = formState

  const setError = (error: string) => {
    setOptions((prevState) => ({
      ...prevState,
      placeOrderError: error,
    }))
  }

  /* eslint complexity: ["error", 100] */
  const onSubmit = handleSubmit(async () => {
    const errorName = 'Order button error'

    if (!broadband && !bundle) {
      const errorMsg = 'Please select a package'
      marketingEvents.error(options, 'ERR-ORDER-1', errorName, errorMsg)
      setError(errorMsg)
      return
    }

    if (!email) {
      const errorMsg = 'Please enter your email'
      marketingEvents.error(options, 'ERR-ORDER-2', errorName, errorMsg)
      setError(errorMsg)
      return
    }

    if (!firstName || !lastName || !phone || !readTerms) {
      const errorMsg = 'Please enter your details'
      marketingEvents.error(options, 'ERR-ORDER-3', errorName, errorMsg)
      setError(errorMsg)
      return
    }

    setError('')

    const client = createCRMApiClient()
    const validation: any = await client.cart.validateCart(cartInstanceIdentifier, channel)

    if (validation.statusCode === '9999') {
      marketingEvents.error(options, 'ERR-ORDER-9999', errorName, validation.statusDescription)
      setError(validation.statusDescription)
      return
    }

    if (!validation.statusCode) {
      const errorMsg = 'An unexpected system error occurred'
      marketingEvents.error(options, 'ERR-ORDER-4', errorName, errorMsg)
      setError(errorMsg)
      return
    }

    const utmParams = await utmStore.get()

    const utmSource = utmParams?.utm_source ?? 'SALES'
    const partnerID = premise?.coverage_indicator! === 'B' ? 'box' : 'cfl'

    const isFree = broadband ? getBaseStandartPrice(broadband) === '0' : false

    if (!isFree) {
      if (!paymentDetails) {
        const errorMsg = 'Please enter your payment details'
        marketingEvents.error(options, 'ERR-ORDER-5', errorName, errorMsg)
        setError(errorMsg)
        return
      }
    }

    router.push(getStepUrl('checkout-order-processing'), undefined, { shallow: true })
    const fullName = `${firstName} ${lastName}`
    const account = await client.submitOrder.createOrder(
      timeslot,
      cartInstanceIdentifier,
      fullName,
      channel,
      partnerID,
      ChannelType.AFFILIATE && cks,
      ChannelType.AFFILIATE && utmSource!,
      reservationId,
    )

    const errorDetailedParams = {
      errorName,
      errorMessage: account.message,
      reservationId,
      cartInstanceIdentifier,
      timeslotId: timeslot?._id!,
      packageId: broadband?.productOfferingId || bundle?.productOfferingId,
      sprn: data?.sprn,
    }

    if ('error' in account) {
      logger.error(account.message, account.error)
      setTimeout(() => {
        localStorage.removeItem('new-order-flow')
      }, 10)

      if (account?.error?.response?.data?.code === 5000) {
        marketingEvents.error(options, 'ERR-ORDER-5000', errorName, account.message)
        location.assign(error_5000_url)
        return
      }

      if (account.error?.response?.data?.code === 5001) {
        marketingEvents.error(options, 'ERR-ORDER-5001', errorName, account.message)
        location.assign(error_5001_url)
        return
      }

      marketingEvents.error(options, 'ERR-ORDER-DEFAULT', errorName, account.message)
      marketingEvents.errorDetailed({
        options,
        ...errorDetailedParams,
        errorCode: 'ERR-DETAILED-ORDER-DEFAULT',
      })
      location.assign(default_error_url + '?error=order')
      return
    }

    if (account.success === false || account.status === '9999') {
      logger.error(account.message, account.response)
      location.assign(default_error_url + '?error=order')
      marketingEvents.error(options, 'ERR-ORDER-9999-2', errorName, account.message)
      marketingEvents.errorDetailed({
        options,
        ...errorDetailedParams,
        errorCode: 'ERR-DETAILED-ORDER-9999-2',
      })
      setTimeout(() => {
        localStorage.removeItem('new-order-flow')
      }, 10)
      return
    }

    setOptions({
      ...options,
      accountId: String(account.orderID),
    })

    await marketingEvents.orderSubmitted(options, String(account.orderID), generalOptions)

    setTimeout(() => {
      localStorage.removeItem('new-order-flow')
    }, 10)

    window.sessionStorage.setItem('requestConfirmed', JSON.stringify(true))
    marketingEvents.orderConfirmation()
    router.push(getStepUrl('checkout-order-confirmation'), undefined, { shallow: true })
  })

  return (
    <FormContext {...methods}>
      <form onSubmit={onSubmit}>
        <Button type="submit" disabled={isSubmitting || (voice && !alternativePhone)} className={classes.orderButton} color="primary" variant="contained" disableElevation id={id}>
          <Typography noWrap>
            Place order
          </Typography>
          {isSubmitting && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
        </Button>
      </form>
    </FormContext>
  )
}
