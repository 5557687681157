import theme from '../../../styles/theme'
import { css } from 'linaria'

const classes = {
  card: css`
    height: 100%;
    display: flex;
    flex-direction: column;
      transition: all 0.2s ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
    ${theme.breakpoints.up('md')} {
      max-height: 687px;
      max-width: auto;
    }
  `,
  image: css`
    border-radius: 12px 12px 0 0;
    height: 200px;
    weight: auto;
    img {
      object-fit: cover;
    }
  `,
  text: css`
    padding-top: ${theme.spacing(4)}px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    .MuiLink-root {
      margin-top: ${theme.spacing(3)}px;
    }
    .MuiTypography-h1 {
      margin-bottom: ${theme.spacing(2)}px;
    }
  `,
}

export default classes
