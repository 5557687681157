import { css } from 'linaria'
import theme, { fontWeights } from './theme'

const commonStyling = {
  backgroundGradient: 'linear-gradient(180deg, white 0%, #F8F8F8 100%)',
  bgGradientPink: 'linear-gradient(90deg, #FFC3DD 0%, #FFF0DD 100%)',
  roundedBorders: {
    all: '20px',
    top: '20px 20px 0 0',
    bottom: '0 0 20px 20px',
    bottomLeft: '0 0 0 20px',
    bottomRight: '0 0 20px 0',
    topLeft: '20px 0 0 0',
    topRight: '0 20px 0 0',
    left: '20px 0 0 20px',
    allSmall: '10px',
  },
  smallRoundedBorders: {
    all: '10px',
    top: '10px 10px 0 0',
    bottom: '0 0 10px 10px',
    bottomLeft: '0 0 0 10px',
    bottomRight: '0 0 10px 0',
    topLeft: '10px 0 0 0',
    topRight: '0 10px 0 0',
    allSmall: '10px',
  },
  breakpoints: {
    desktopLarge: '800px',
    desktopSmall: '580px',
  },
  svgFontSize: '40px',
  svgFontSizeSmall: '30px',
  boxShadowLight: '0px 3px 40px #00000014',
  boxShadowDark: '0px 3px 40px #0000001F',
  maxWidthHalfGridLeft: css`
    position: relative;
    ${theme.breakpoints.up('md')} {
      left: 22px;
      max-width: 620px;
    }
  `,
  loadingSpinner: css`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    & div {
      position: absolute;
      border: 4px solid #f7931e;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    & div:nth-child(2) {
      animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
      0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
      }
    }
  `,
  minSizeClickableTab: '36px',
  imageCardMaxWidth: '368px',
  differentFontWeightOnHover: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    &:after {
      content: attr(data-text);
      content: attr(data-text) / "";
      height: 0px;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: ${fontWeights.semibold};
      @media speech {
        display: none;
      }
    }
  `,
}

export default commonStyling
