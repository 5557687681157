function getEnvVar(envVarName: string): string {
  const value =
    process.env[`${envVarName}`] || process.env[`NEXT_PUBLIC_${envVarName}`]
  if (process.env.STORYBOOK_FLAG) {
    return 'none'
  }

  if (!value) {
    throw new Error(`Environment variable ${envVarName} is not set`)
  }

  return value
}

const env = Object.freeze({
  CRMAccessToken: getEnvVar('CRM_ACCESS_TOKEN'),
  CRMApiUrl: getEnvVar('CF_CRM_API_URI'),
  AddressSearchUrl: getEnvVar('ADDRESS_SEARCH_SERVICE'),
  BusinessAddressSearch: getEnvVar('BUSINESS_SEARCH_SERVICE'),
  BillingApiUrl: getEnvVar('CF_BILLING_API_URI'),
  CRMSchedulerUrl: getEnvVar('CF_SCHEDULER_API_URI'),
  CRMClientSecret: getEnvVar('CRM_CLIENT_SECRET'),
  butterCMSToken: getEnvVar('BUTTER_CMS_TOKEN'),
  requestSource: getEnvVar('REQUEST_SOURCE'),
  googleMapKey: getEnvVar('GMAP_KEY'),
  mailchimpApiKey: getEnvVar('MAILCHIMP_API_KEY'),
  CFOAuthApiUrl: getEnvVar('CF_CFO_AUTH_API_URI'),
  CFOAuthClientId: getEnvVar('CF_CFO_AUTH_CLIENT_ID'),
  CFOAuthClientSecret: getEnvVar('CF_CFO_AUTH_CLIENT_SECRET'),
  CFOAuthClientScope: getEnvVar('CF_CFO_AUTH_SCOPE'),
  CFOAuthPostClientId: getEnvVar('CF_CFO_AUTH_POST_CLIENT_ID'),
  CFOAuthPostClientSecret: getEnvVar('CF_CFO_AUTH_POST_CLIENT_SECRET'),
  CFOAuthPostClientScope: getEnvVar('CF_CFO_AUTH_POST_SCOPE'),
  ZinierApiUrl: getEnvVar('ZINIER_API_URI'),
  ExperianApiUrl: getEnvVar('EXPERIAN_API_URI'),
  PhoenixApiUrl: getEnvVar('CF_PHOENIX_API_URI'),
  HobsApiUrl: getEnvVar('HOBS_API_BASE_URI'),
  EncryptSecretPhrase: getEnvVar('ENCRYPT_SECRET_PHRASE'),
  HobsApiKey: getEnvVar('HOBS_API_KEY'),
  PaymentApiUrl: getEnvVar('PAYMENT_API_URI'),
  PinpointApiUrl: getEnvVar('PINPOINT_API_URI'),
  PremiseCoverageApiUrl: getEnvVar('PREMISE_COVERAGE_SERVICE'),
  Cryptokey: getEnvVar('CRYPTO_KEY'),
  CryptoKeyIv: getEnvVar('CRYPTO_KEY_IV'),
})

export default env
