import {
  css,
} from 'linaria'
import fontSizes from '../../../../../styles/fontSizes'
import theme from '../../../../../styles/theme'

export const classes = {
  checkboxesContainer: css`
    &.MuiFormControlLabel-root {
      box-sizing: border-box;
      padding: ${theme.spacing(0, 2, 0)};
      height: 100%;
      margin: auto;
      width: 100%;
      align-items: flex-start;
      color: ${theme.palette.primary.main};
    }
    .MuiRadio-root, .MuiRadio-colorSecondary.Mui-checked {
      color: ${theme.palette.primary.main};
    }
    .MuiTypography-body2{
      font-weight: ${theme.typography.fontWeightLight!}
    }
`,
  portOptionSelect: css`
    border: 0px;
  `,
  radioGroup: css`
    padding-bottom: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `,
  inputTitle: css`
    .MuiInput-formControl {
      margin-top: ${theme.spacing(3)}px;
    }
  `,
  selectLabel: css`
    margin-bottom: ${theme.spacing(1)}px;
    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacing(2)}px;
      &.MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(0.95) !important;
      }
    }
  `,
  select: css`
    width: 100%;
    .MuiNativeSelect-select {
      background-color: #F8F8F8;
      border-radius: 50px;
      overflow: hidden;
      width: 100%;
      padding-left: ${theme.spacing(3)}px;
      padding-top: 12px;
      padding-bottom: 14px;
      font-size: ${fontSizes.body2};
      font-weight: ${theme.typography.fontWeightLight!};
      font-size: 18px;
    }
    .MuiNativeSelect-icon {
      right: ${theme.spacing(2)}px;
      color: ${theme.palette.primary.main};
    }
    &.Mui-focused {
      .MuiNativeSelect-select {
        border-radius: 50px;
      }
    }
  `,
  formSuccess: css`
    padding: ${theme.spacing(5)}px;
    height: 400px;

    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  checkbox: css`
    &.MuiButtonBase-root {
      padding-top: 3px;
      padding-left: 0px;
    }
  `,
  prevProvContainer: css`
    &.MuiGrid-root {
      ${theme.breakpoints.up('md')} {
        padding-right: ${theme.spacing(2)}px;
      }
    }
  `,
  confirmButtonStyle: css`
    &.MuiGrid-root.MuiGrid-item {
      padding-top: ${theme.spacing(4)}px;
    }
    button {
      padding:0 ${theme.spacing(8)}px!important;
    }
  `,
  noXPaddingOnMobile: css`
    &.MuiGrid-root.MuiGrid-item {
      ${theme.breakpoints.down('md')} {
        padding-left: 0;
        padding-right: 0;
      }
    }
  `,
  firstInputTopPadding: css`
    &.MuiGrid-root.MuiGrid-item {
      padding-top: ${theme.spacing(2)}px;
    }
  `,
  secondInputTopPadding: css`
    &.MuiGrid-root.MuiGrid-item {
      padding-top: ${theme.spacing(2)}px;
    }
  `,
}
