import React from 'react'
import { Box } from '@material-ui/core'
import {
  COLORS, ROUNDED,
} from '../../styles/theme'

interface SimpleBoxProps {
  children: React.ReactNode;
  id?: string;
  bgcolor?: string;
}

const SimpleBox = ({
  children,
  id,
  bgcolor = COLORS.gray1,
}: SimpleBoxProps) => {
  return (
    <Box p={3} borderRadius={ROUNDED.medium} id={id} bgcolor={bgcolor}>
      {children}
    </Box>
  )
}

export default SimpleBox
