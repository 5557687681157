import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'

import fontSizes from '../../styles/fontSizes'

const classes = {
  container: css`
    max-width: 345px;
    margin: 0 auto;
  `,
  checkBox: css`
  .MuiCheckbox-root{
    color: ${COLORS.primary};
    padding:0;
    margin-right:8px;
  }
  `,
  tabsWrapper: css`
    display:flex;
    justify-content: center;
    gap:8px;
    .MuiTab-root {
      padding-right: 0;
      padding-left: 0;
      color: ${COLORS.bodyTextPrimary};
      max-width: 52px;
      min-width: 52px;
    }

    .MuiTab-wrapper {
      max-width: 10%;
      font-size: ${fontSizes.body2};
      line-height: 1.05;
      font-weight: ${theme.typography.fontWeightRegular!};
    }
  `,
  speedText: css`
    display:flex;
    gap:3px;
    font-size: ${fontSizes.subtitle3};
    line-height: 1.3;
    font-weight: 600;
  `,
}

export default classes
