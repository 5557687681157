import React from 'react'
import {
  Tab as MuiTab, Tabs as MuiTabs,
} from '@material-ui/core'
import theme, {
  COLORS, fontWeights,
} from '../../styles/theme'
import { TypographyProps } from '../Typography/Typography'
import {
  createStyles, withStyles,
} from '@material-ui/styles'

export interface Tab {
  type: 'ANNUAL_24' | 'ANNUAL' | 'MONTHLY';
  label: string;
  title_text?: string;
  body_text?: string;
  title_2?: TypographyProps;
  subtitle?: TypographyProps;
  contract_length?: number;
}

interface PricingPackageTabsProps {
  active: number;
  onChange: (_event: React.ChangeEvent<{}>, newActiveIndex: number) => void;
  tabs: Tab[];
}

interface StyledTabProps {
  label: string;
}

const a11yProps = (index: number) => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
})

const Tabs = withStyles({
  root: {
    padding: '2px',
    minHeight: 46,
    textAlign: 'center',
  },
  flexContainer: {
    display: 'inline-flex !important',
  },
  indicator: {
    backgroundColor: COLORS.primary,
    borderRadius: 100,
    height: '100%',
  },
})(MuiTabs)

const Tab = withStyles(() =>
  createStyles({
    root: {
      '&.MuiTab-textColorInherit': {
        color: COLORS.primary,
        fontWeight: fontWeights.semibold,
        minHeight: 46,
        minWidth: 140,
        opacity: 1,
      },
      '&.MuiButtonBase-root': {
        border: `1px solid ${COLORS.primary}`,
        borderRadius: 100,
      },
      '&$selected': {
        color: COLORS.white,
        zIndex: 2,
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <MuiTab disableRipple {...props}/>)

export default function PricingPackageTabs({
  active = 0,
  onChange,
  tabs,
}: PricingPackageTabsProps) {
  return (
    <Tabs
      value={active}
      onChange={onChange}
      id="termToggler"
      variant={tabs && tabs.length <= 2 ? 'standard' : 'scrollable'}
    >
      {tabs && tabs.length > 0 && tabs.map((tab: Tab, i: number) => (
        <Tab
          label={tab.label}
          key={i}
          style={{
            minWidth: 'fit-content',
            padding: `0 ${theme.spacing(4)}px`,
            marginRight: 5,
          }}
          {...a11yProps(i)}
        />
      ))}
    </Tabs>
  )
}
