import React from 'react'
import { css } from 'linaria'
import {
  Link,
  Typography,
} from '@material-ui/core'
import theme from '../../../styles/theme'

import { BlogPost } from '../../../utils/blog'
import Tile from '../Tile/Tile'

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const classes = {
  cardText: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .MuiTypography-h4 {
      margin: ${theme.spacing(1)}px 0;
    }
  `,
  readMore: css`
    &.MuiTypography-overline {
      margin-top: ${theme.spacing(2)}px;
      text-decoration: underline;
    }
  `,
}

export default function PressReleaseTile({
  featured_image, slug, title, published,
}: BlogPost) {
  let date: Date
  let dateString = ''

  if (published) {
    date = new Date(published)
    dateString = `${date.getDate()} ${MONTH_NAMES[date.getMonth()]} ${date.getFullYear()}`
  }

  return (
    <Tile featured_image={featured_image} title={title}>
      <div className={classes.cardText}>
        <div>
          <Typography variant="overline">{dateString}</Typography>
          <Typography component="h2" variant="h4">{title}</Typography>
        </div>
        <Link variant="overline" underline="none" color="secondary" className={classes.readMore} href={`/press/${slug}`}>Read More</Link>
      </div>
    </Tile>
  )
}
