import React from 'react'
import {
  CardContent, Box,
} from '@material-ui/core'
import classes from './Tile.styles'
import MediaImage from '../../Media/MediaImage'
import {
  BOX, ROUNDED,
} from '../../../styles/theme'

interface TileProps {
  featured_image: string;
  title: string;
  children: React.ReactNode;
}

export default function Tile({
  featured_image, title, children,
}: TileProps) {
  return (
    <Box
      {...BOX}
      width={360}
      className={classes.card}
    >
      <Box overflow="hidden" borderRadius={`${ROUNDED.medium}px ${ROUNDED.medium}px 0 0`}>
        <MediaImage
          className={classes.image}
          src={featured_image}
          width={380}
          height={200}
          alt={title}
          full_width
        />
      </Box>
      <Box
        p={4}
        component={CardContent}
        className={classes.text}
      >
        {children}
      </Box>
    </Box>
  )
}
