import theme, { COLORS } from '../../../../../../styles/theme'
import { css } from 'linaria'
import fontSizes from '../../../../../../styles/fontSizes'
import commonStyling from '../../../../../../styles/commonStyling'

const classes = {
  wrapper: css`
    color: ${theme.palette.primary.main};
    padding-bottom: ${theme.spacing(7)}px;
  `,
  wrapperInner: css`
     max-width: ${commonStyling.breakpoints.desktopLarge};
  `,
  curveContainer: css`
    padding: ${theme.spacing(7, 0)};
    .MuiTypography-root {
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h2};
      }
    }
    .MuiTypography-h1{
      margin-bottom: ${theme.spacing(4)}px;
      font-size: ${fontSizes.h2};
    }
    .MuiTypography-h2 {
      font-size: ${fontSizes.body2};
      padding-bottom: ${theme.spacing(2)}px;
      ${theme.breakpoints.up('sm')} {
        font-size: ${fontSizes.h3};
        padding-bottom: ${theme.spacing(4)}px;
      }
    }
    .MuiTypography-h3 {
      font-size: ${fontSizes.body2};
      ${theme.breakpoints.up('sm')} {
        font-size: ${fontSizes.h5};
        padding-bottom: ${theme.spacing(4)}px;
      }
    }
  `,
  subTitle: css`
    text-align: center;
    position: relative;
    top: -40px;
    div {
      background-color: white;
      border-radius: 50px;
      box-shadow: ${commonStyling.boxShadowLight};
      padding: ${theme.spacing(2, 5)};
      display: inline-block;
    }
    .MuiTypography-root {
      position: relative;
      bottom: 3px;
    }
  `,
  orderInfo: css`
    max-width: ${commonStyling.breakpoints.desktopLarge};
    margin: ${theme.spacing(2)}px auto 0;
    ${theme.breakpoints.up('md')} {
      >:nth-child(even) {
        margin-left:${theme.spacing(3)}px;
        margin-right:${theme.spacing(3)}px;
      }
      >:nth-child(4) {
        padding-top: ${theme.spacing(5)}px;
      }
      >[class*="productInfoGrid"]{
        max-width: 250px;
        }
    }
  `,
  productInfoGrid: css`
    img {
      max-width: 40px;
      margin-bottom: ${theme.spacing(2)}px;
    }
    .MuiTypography-h5 {
      font-weight: ${theme.typography.fontWeightMedium!};
    }
    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
      color: ${COLORS.bodyTextPrimary};
      a {
        color: ${theme.palette.secondary.contrastText};
      }
    }
    .MuiTypography-body1 > div > p {
      margin: ${theme.spacing(1, 0, 0)};
    }
    ${theme.breakpoints.down('sm')} {
      margin-left:${theme.spacing(2)}px;
    }
    ${theme.breakpoints.up('md')} {
      text-align: center;
      flex-direction: column;
      align-items: center;
      .MuiTypography-root {
        padding-left: 0;
      }
      img {
        max-width: 64px;
      }
    }
  `,
}

export default classes
