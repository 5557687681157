import React, {
  useContext, useEffect, useState,
} from 'react'
import { PremiseContext } from '../../../PremiseTracker/PremiseContext'
import { NextRouter } from 'next/router'
import Link from 'next/link'
import PersonalDetailsForm, { PersonalDetailsFormData } from './PersonalDetailsForm/PersonalDetailsForm'
import {
  Grid, Typography, Collapse, Hidden,
} from '@material-ui/core'
import classes from './CheckoutStep.styles'
import { Discount } from '../../../../utils/commonInterfaces'
import { ArrowBack } from '@material-ui/icons'
import getStepUrl from '../../getStepUrl'
import { useOrderContext } from '../../OrderContext'
import InstallationSection from './InstallationSection/InstallationSection'
import PaymentDetails from './PaymentDetails/PaymentDetails'
import TimeoutModal from './TimeoutModal/TimeoutModal'
import marketingEvents from '../../../../utils/marketing/marketingEvents'
import Basket from './Basket/Basket'
import OrderButton from './Basket/OrderButton'
import OrderError from './OrderError/OrderError'
import OrderProcessing from './OrderProcessing/OrderProcessing'
import SummarySection from './SummarySection/SummarySection'
import { ContainerWithPadding } from '../../../../styles/ContainerWithPadding'
import createCRMApiClient from '../../../../api/CRMApi'
import {
  general,
} from '../../../../styles/theme'
import MediaImage from '../../../Media/MediaImage'
import Stepper from './Stepper/Stepper'
import WarningMessage, { WarningMessageColor } from '../../../WarningMessage/WarningMessage'
import {
  scrollStepIntoView, jumpToElement,
} from '../../../../utils'
import { ButterCMSComponent } from '../../../../butter'
import { getPackageLength } from '../../../../utils/getProductDetails'
import { cx } from 'linaria'

interface Props {
  router: NextRouter;
  step: StepType;
  generalSettings: any;
  data: {
    components: ButterCMSComponent[];
  };
}

interface Response {
  data: {
    stage: {
      name: String;
    };
  };
  message: string;
  error: string;
}

export interface OrderDetails {
  data: PersonalDetailsFormData;
  discount: Discount | null;
  promo: any | null;
}

export type ChangeCollapsible = (nextCollapsible: StepType, previousCollapsible?: StepType) => void;

export type StepType =
  | 'broadband'
  | 'addons'
  | 'checkout'
  | 'checkout-details'
  | 'checkout-installation'
  | 'checkout-payment'
  | 'checkout-order-summary'
  | 'checkout-order-processing'
  | 'checkout-order-confirmation'

export const checkOutPageSteps = [
  'checkout',
  'checkout-details',
  'checkout-installation',
  'checkout-payment',
  'checkout-order-summary',
]

export default function CheckoutStep({
  router, step: currentStep, generalSettings, data,
}: Props) {
  const { premise } = useContext(PremiseContext)
  const {
    options, setOptions,
  } = useOrderContext()

  const {
    broadband,
  } = options

  const specialContractLengthList = generalSettings.components.find((e: any) => e.type === 'general')?.special_contract_length
  const specialContractLengthArray = specialContractLengthList ? specialContractLengthList.split('|') : []
  const productContractLength = String(getPackageLength(broadband))

  useEffect(() => {
    marketingEvents.onCheckoutOption(1)
    marketingEvents.onCheckoutSection('email')
    scrollStepIntoView('#stepper', 100)

    if (currentStep === 'checkout' || currentStep === 'checkout-details') {
      const headerRef: HTMLElement | null = document.querySelector('#header')
      if (headerRef) {
        jumpToElement(headerRef, false)
      }
    }

    return undefined
  }, [currentStep])

  useEffect(() => {
    if (currentStep === 'checkout-details') {
      marketingEvents.ecommerceEvent('begin_checkout', options)
      setOptions({
        ...options,
        isDetailsStep: true,
      })
    }

    if (currentStep === 'checkout-payment') {
      marketingEvents.ecommerceEvent('add_payment_info', options)
    }

    if (currentStep === 'checkout-installation') {
      setOptions({
        ...options,
        reachedInstallationStep: true,
        isDetailsStep: false,
      })
    }

    if (currentStep === 'checkout-order-processing') {
      marketingEvents.simpleEvent('order_processing', options)
    }

    if (currentStep === 'checkout-order-summary') {
      marketingEvents.simpleEvent('order_summary', options)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  useEffect(() => {
    if (currentStep === 'checkout-details') {
      if (premise?.address) {
        verifyAddress(premise!.address)
        verifyAddressWithHobs(premise!.sprn.toString())
      }
    }
  }, [
    currentStep,
    premise,
  ])

  const saveFormDetails = (formData: OrderDetails) => {
    if (formData.data) {
      setOptions({
        ...options,
        data: {
          ...options?.data,
          ...formData.data,
        },
        promo: options?.promo ?? null,
      })
    } else {
      setOptions({
        ...options,
        ...formData,
      })
    }
  }

  const changeCollapsible = (nextCollapsible: StepType) => {
    if (nextCollapsible === 'checkout-payment' && premise?.type === 'Community') {
      router.push(getStepUrl('checkout-order-summary'), undefined, { shallow: true })
    } else {
      router.push(getStepUrl(nextCollapsible), undefined, { shallow: true })
    }
  }

  const [
    verifiedAddress,
    setVerifiedAddress,
  ] = useState<boolean>(false)

  const [
    verifiedAddressHobs,
    setVerifiedAddressHobs,
  ] = useState<boolean>(false)
  const client = createCRMApiClient()

  const verifyAddress = async (address: string) => {
    const { data } = await client.accounts.verifyAddress(address) as Response
    const stage = data?.stage.name
    if (data && stage !== 'INACTIVE') {
      setVerifiedAddress(true)
    }
  }

  const verifyAddressWithHobs = async (sprnNumber: string) => {
    const response = await client.payment.verifyAddressWithHobs({ sprn: `${sprnNumber}` }) as any
    const statusCode = response?.statusCode
    if (statusCode && statusCode === '2000') {
      setVerifiedAddressHobs(true)
    }
  }

  switch (currentStep) {
    case 'checkout-order-processing':
      return <OrderProcessing/>
    default:
      return (
        <>
          <ContainerWithPadding>
            {!specialContractLengthArray.includes(productContractLength) &&
            <Stepper currentStep={currentStep}/>}
          </ContainerWithPadding>
          <ContainerWithPadding>
            <Grid container justify="space-between" className={classes.container}>
              <Grid item xs={12} md={8} className={classes.leftContainer}>
                {(verifiedAddress ||
                verifiedAddressHobs) &&
                <WarningMessage
                  color={WarningMessageColor.yellow}
                  text="<strong><b>Sorry, you cannot proceed with this order</b></strong></br></br>
      We detected existing equipment at this address (please double check that the address below is correct)</br>
      If you need help, please visit our&nbsp;<a href='https://help.communityfibre.co.uk' target='_blank' rel='noopener noreferrer'>help pages</a> or call&nbsp;<a href='tel:08000820770'>0800 082 0770</a>."
                />}
                {currentStep === 'checkout-details' &&
                <Grid item container xs={12} className={classes.positionRelative}>
                  {!specialContractLengthArray.includes(productContractLength) &&
                  <Grid item xs={8} md={10}>
                    <Link href={getStepUrl('broadband', premise?.sprn)}>
                      <a className={classes.backButton}>
                        <ArrowBack/>
                        <Typography variant="body1" color="primary">
                          Back to packages
                        </Typography>
                      </a>
                    </Link>
                  </Grid>}
                  <Grid item xs={4} md={2} className={cx(specialContractLengthArray.includes(productContractLength) && classes.iconAbsolute)}>
                    <MediaImage
                      src="/60DaysFreeCancelationIcon.svg"
                      width={90}
                      height={90}
                      alt="60 days free cancelation"
                      center
                    />
                  </Grid>
                </Grid>}
                <Collapse
                  in={currentStep === 'checkout-details'}
                >
                  <Typography variant="h1" color="primary" className={general.font.weight.semibold}>
                    Your Details
                  </Typography>
                  <PersonalDetailsForm
                    changeCollapsible={changeCollapsible}
                    saveFormDetails={saveFormDetails}
                    verifiedAddress={verifiedAddress}
                    verifiedAddressHobs={verifiedAddressHobs}
                  />
                </Collapse>
                <Collapse
                  in={currentStep === 'checkout-installation'}
                >
                  <Typography variant="h1" color="primary" className={general.font.weight.semibold}>
                    Installation
                  </Typography>
                  <InstallationSection changeCollapsible={changeCollapsible} settings={generalSettings}/>
                </Collapse>
                {premise?.type !== 'Community' &&
                <Collapse
                  in={currentStep === 'checkout-payment'}
                >
                  <Typography variant="h1" color="primary" className={general.font.weight.semibold}>
                    Payment
                  </Typography>
                  <PaymentDetails changeCollapsible={changeCollapsible}/>
                </Collapse>}
                <Collapse
                  in={currentStep === 'checkout-order-summary'}
                >
                  <Typography variant="h1" color="primary" className={general.font.weight.semibold}>
                    Review your order
                  </Typography>
                  <SummarySection summaryData={data}/>
                </Collapse>
                <Hidden mdUp>
                  <Grid className={classes.directDebit} container alignItems="center" justify="center">
                    <Grid item xs={3} sm={2}>
                      <img src="/directDebitLogo.svg" alt="Direct debit"/>
                    </Grid>
                    <Grid item xs={9} sm={10}>
                      <Typography variant="body2">
                        Your payments are protected by the <a href="/legal-stuff#Direct-Debit-Guarantee" target="_blank" rel="noopener noreferrer">Direct Debit Guarantee</a>
                      </Typography>
                    </Grid>
                  </Grid>
                  {currentStep === 'checkout-order-summary' &&
                  <div className={classes.mobileOrderButton}>
                    {options.placeOrderError && <OrderError error={options.placeOrderError}/>}
                    <OrderButton id="CheckoutStep_OrderButton"/>
                  </div>}
                </Hidden>
              </Grid>
              <Grid item xs={12} md={3}>
                {(options.broadband || options.bundle) &&
                <Basket
                  broadband={options.broadband}
                  bundle={options.bundle}
                  timeslot={options.timeslot}
                  reachedInstallationStep={options.reachedInstallationStep}
                />}
              </Grid>
              <TimeoutModal changeCollapsible={changeCollapsible}/>
            </Grid>
          </ContainerWithPadding>
        </>
      )
  }
}
