import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  modalScreen: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    div {
      outline: none;
    }
  `,
  close: css`
    cursor: pointer;
    height: 48px;
    position: absolute;
    top: ${theme.spacing(1.5)}px;
    right: ${theme.spacing(1.5)}px;
  `,
}

export default classes
